import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useState, useContext, ReactElement, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import AdminIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SubMenuIcon from '@material-ui/icons/ArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InvestmentsIcon from '@material-ui/icons/InsertChartOutlined';
import ReportsIcon from '@material-ui/icons/AssignmentOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BookIcon from '@material-ui/icons/LocalLibraryOutlined';
import SurveyIcon from './icons/SurveyIcon';
import OrgDetailsIcon from './icons/OrgDetails';

import { DirtyContext } from 'components/Dirty/Context';
import { noBGList } from './Container';
import { ADD_REMOVE_FLOIDS_PERMISSION, PERMISSIONS } from 'appConstants';
import { report, selectReport } from 'features/SPOReport/redux';
import { selectProfile } from 'features/login/redux';
import { useAppSelector } from 'app/hooks';
import * as S from './NavBar.styled';
import Modal from 'components/Modal';

import { LanguageContext } from 'components/Language/Context';

function NavBar() {
  const { locale } = useContext(LanguageContext);
  const [open, setOpen] = useState<any>({});
  const history = useHistory();
  const location = useLocation();
  const { isDirty, setDirty, showModal, setShowModal, linkTo, setLinkTo } = useContext(DirtyContext);
  const dispatch = useDispatch();
  const profile = useAppSelector(selectProfile);
  const [menuItems, setMenuItems] = useState<any>(menu);

  useEffect(() => {
    if (
      profile &&
        profile.permissions &&
        profile.permissions.findIndex(
          (id) => [PERMISSIONS.FIELD_STAFF_VIEW].indexOf(id) >= 0
        ) < 0 &&
        menu[2].label === 'reports'
    ) {
      const menu = [...menuItems];
      // menu.splice(2, 1); // comment out because it's causing other bugs
      // todo plz check if the code above only wants to remove 'reports' tab from sidebar
      const updateMenu = menu.filter(item => item?.label !== 'reports');
      setMenuItems(updateMenu);
    }
    /* eslint-disable-next-line */
  }, [profile]);

  function subActive(link: string, onPage?: string[]): boolean {
    let active = location.pathname === link;
    if (!active && onPage) {
      active =
          onPage.findIndex((p) => new RegExp(p).test(location.pathname)) >= 0;
    }
    return active;
  }

  function doLink(link: string) {
    if (isDirty) {
      setShowModal(true);
      setLinkTo(link);
      return;
    }
    dispatch(report(null));
    window.localStorage.removeItem('report');
    history.push(link as string);
  }

  const title: ReactElement = (
    <span style={{ color:'#000', fontSize: '20px', fontWeight:700, lineHeight:'40px' }}>
      <FormattedMessage id='isDirty' />
    </span>
  );

  const body: ReactElement = (
    <Row>
      <Col style={{ color:'#706D6D', fontSize: '14px',fontWeight:400, marginBottom:'10px' }}>
        <FormattedMessage id='dirtyConfirm' />
      </Col>
    </Row>
  );

  const footer: ReactElement = (
    <Row>
      <Col>
        <Button onClick={() => setShowModal(false)} style={{ color:'#2C3D52', backgroundColor:'white', marginRight:'5px', border:'none', width:'50px', fontSize: '16px',fontWeight:700, lineHeight:'26px' }}>
          <FormattedMessage id='cancel' />
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            setDirty(false);
            setShowModal(false);
            linkTo && history.push(linkTo);
          }}
          variant='success'
          style={{ color:'white', backgroundColor:'#2C3D52', border:'none', width:'84px', fontSize: '16px', fontWeight:700, padding:'10px 12px', lineHeight:'20px' }}
        >
          <FormattedMessage id='ok' />
        </Button>
      </Col>
    </Row>
  );

  const noBG = noBGList.find((item) =>
    typeof item === 'string'
      ? item === location.pathname
      : item.test(location.pathname)
  );

  return (
    <S.Container>
      {showModal && (
        <Modal
          show={showModal}
          handleClose={() => setShowModal(false)}
          title={title}
          body={body}
          footer={footer}
          size='lg'
          style={{ width:'640px' }}
        />
      )}

      <S.Logo>
        <S.LogoImage src={Logo} data-testid='logo-svg' />
      </S.Logo>

      <S.Unorderedlist>
        {menuItems.map(
          ({
            label,
            icon,
            link,
            customMethodLink,
            subMenu,
            isActive,
            permission,
            testId
          }: any) => {
            const perms = profile?.permissions || [];
            if (
              permission &&
                    !profile?.permissions?.find((id) =>
                      permission.find((x) => x === id)
                    )
            )
              return null;

            const shouldShowPage = (perms, permission, permissionNotShow) => {
              if (permission.length || permissionNotShow.length) {
                const showMenu = perms.some((id) => permission.includes(id));
                const hideMenu = perms.some((id) =>
                  permissionNotShow.includes(id)
                );
                return showMenu && !hideMenu;
              }
              return true;
            };

            // Hide menu if there's no child menu showing
            if ((subMenu || []).length && !link) {
              const hasActiveChild = subMenu.some((item) => {
                return shouldShowPage(
                  perms,
                  item.permission || [],
                  item.permissionNotShow || []
                );
              });
              if (!hasActiveChild) return null;
            }

            const active = !!(
              isActive.findIndex((x) => {
                const pathChecker = new RegExp(`^${x}`, 'g').test(location.pathname);
                return x === ''
                  ? location.pathname === '/'
                  : pathChecker; // Old Logic = location.pathname.includes(x);
              }) >= 0
            );
            const subOpen = subMenu
              ? subMenu.findIndex(({ link, onPage }: any) =>
                subActive(link, onPage)
              ) >= 0
              : false;
            const isSubOpen = (subMenu && subOpen) || (subMenu && open[label]);

            return (
              <S.List key={label}>
                <S.TopMenu
                  data-testid={testId}
                  $onPage={active}
                  $active={open[label] || subOpen}
                  $subActive={!subActive}
                  onClick={() => {
                    if (subMenu && !subOpen) {
                      setOpen({ ...open, [label]: !open[label] });
                    } else if (customMethodLink && typeof customMethodLink === 'function') {
                      customMethodLink(locale);
                    } else {
                      doLink(link);
                    }
                  }}
                >
                  <S.Icon $onPage={active}>{icon}</S.Icon>
                  <S.span $onPage={active}>
                    <FormattedMessage id={label} />
                  </S.span>
                  {subMenu && (
                    <S.Icon $onPage={active}>
                      {!open[label] && !subOpen ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </S.Icon>
                  )}
                </S.TopMenu>
                {isSubOpen && subMenu && (
                  <S.SubMenu $active={open[label] || isSubOpen}>
                    {subMenu.map(
                      ({
                        label,
                        subLabel,
                        link,
                        onPage,
                        testId,
                        permission,
                        permissionNotShow
                      }: any) => {
                        if (permission && typeof permission === 'number' && !profile?.permissions?.find((id) => permission === id)){
                          return null;
                        }else if (permission && Array.isArray(permission) && !permission.some(perm => profile.permissions.includes(perm))){
                          return null; //for PO-Proxy, permission 56 is used to access manage org, therefore, permission could become an array here
                        } else if (permissionNotShow && permissionNotShow?.length && permissionNotShow.some(perm => profile.permissions.includes(perm))) {
                          return null;
                        }
                        const active = subActive(link, onPage);
                        return (
                          <S.SubMenuItem
                            data-testid={testId}
                            key={label}
                            $onPage={active}
                            onClick={() => {
                              localStorage.removeItem('selectedWrapperType');
                              localStorage.removeItem('selectedwrapperId');
                              localStorage.removeItem('selectedWrapperYear');
                              doLink(link as string);
                            }}
                          >
                            {subLabel && (
                              <span>
                                <FormattedMessage id={subLabel} />
                              </span>
                            )}
                            <FormattedMessage id={label} />
                            {active ? (
                              <SubMenuIcon
                                style={{
                                  color: !noBG ? '#FFF' : '#ececf5',
                                  position: 'absolute',
                                  right: '-1px',
                                  fontSize: '5em',
                                  marginTop: '-20px',
                                  marginRight: '-0.42em'
                                }}
                              />
                            ) : null}
                          </S.SubMenuItem>
                        );
                      }
                    )}
                  </S.SubMenu>
                )}
              </S.List>
            );
          }
        )}
      </S.Unorderedlist>
    </S.Container>
  );
}

import Logo from 'assets/img/logo_315x80.png';
// import { Report } from '../../../types';

const menu = [
  {
    testId: 'home',
    label: 'home',
    link: '/',
    isActive: [''],
    icon: <HomeIcon />
  },
  {
    testId: 'organization-details',
    label: 'OrganizationDetails',
    isActive: [
      '/organizationDetails/geolocationLinks',
      '/organizationDetails/organizationConsents',
      '/organizationDetails/manage/geolocationLinks',
    ],
    permission: [
      PERMISSIONS.FTI_ADMIN_READ,
      PERMISSIONS.FTI_ADMIN_WRITE,
      PERMISSIONS.GEOLINKS,
      PERMISSIONS.PO_WITH_GEOLINK,
      PERMISSIONS.USER_WITH_ORG_GEOLINK
    ],
    icon: <OrgDetailsIcon />,
    subMenu: [
      {
        testId: 'org-details-geolocation-links',
        label: 'GeolocationLinks',
        link: '/organizationDetails/geolocationLinks',
        onPage: [/^\/organizationDetails\/geolocationLinks$/],
        permission: [PERMISSIONS.GEOLINKS, PERMISSIONS.PO_WITH_GEOLINK, PERMISSIONS.USER_WITH_ORG_GEOLINK],
      },
      {
        testId: 'admin-geolocation-links',
        label: 'ManageGeolocationLinks',
        link: '/organizationDetails/manage/geolocationLinks',
        onPage: [/^\/organizationDetails\/manage\/geolocationLinks$/],
        permission: [PERMISSIONS.FTI_ADMIN_READ, PERMISSIONS.FTI_ADMIN_WRITE]
      },
    ],
  },
  {
    testId: 'investment-projects',
    label: 'DevelopmentPlans',
    icon: <InvestmentsIcon />,
    isActive: [
      '/reportsList',
      '/report/draft',
      '/report/approved',
      '/report/past',
      '/reports/'
    ],
    subMenu: [
      {
        testId: 'draft-plans',
        label: 'draftPlans',
        link: '/reportsList/draft',
        onPage: [
          /\/reportsList\/draft\/(\d+)$/,
          /\/report\/draft\/(\d+)$/,
          /\/reports\/(\d+)\/([a-z]+)/,
          /\/reports\/new\/([a-z]+)/
        ]
      },
      {
        testId: 'approved-plans',
        label: 'approvedPlans',
        link: '/reportsList/approved',
        onPage: [
          /\/reportsList\/approved\/(\d+)\/([a-z]+)$/,
          /\/report\/approved\/(\d+)\/([a-z]+)$/,
          /\/report\/approved\/(\d+)$/,
          /\/report\/approved\/(-\d+)\/([a-z]+)$/
        ]
      },
      {
        testId: 'pastPlans',
        label: 'past-plans',
        link: '/reportsList/past',
        onPage: [
          /\/reportsList\/past\/(\d+)\/([a-z]+)$/,
          /\/report\/past\/(\d+)\/([a-z]+)$/,
          /\/report\/past\/(\d+)$/
        ]
      },
      {
        testId: 'bulkUpload',
        label: 'BulkUpload',
        link: '/reportsList/bulk',
        onPage: [
          /\/reportsList\/bulk\/(\d+)\/([a-z]+)$/,
          /\/report\/bulk\/(\d+)\/([a-z]+)$/,
          /\/report\/bulk\/(\d+)$/
        ]
      }
    ]
  },
  {
    testId: 'reports-menu',
    label: 'reports',
    link: '/informationHub',
    isActive: ['/fieldStaff'],
    icon: <ReportsIcon />,
    subMenu: [
      {
        testId: 'field-stafff',
        label: 'fieldStaffView',
        subLabel: 'beta',
        link: '/fieldStaff',
        onPage: [/\/fieldStaff$/]
      }
    ]
  },
  {
    testId: 'survey-menu',
    label: 'Surveys',
    isActive: ['/latestSurveys', '/surveyList', '/takeSurvey', 'surveyItem'],
    permission: [PERMISSIONS.SURVEY_CREATE, PERMISSIONS.SURVEY_TAKE],
    icon: <SurveyIcon />,
    subMenu: [
      {
        testId: 'survey-take',
        label: 'Take',
        link: '/takeSurvey',
        isActive: ['/takeSurvey'],
        permission: PERMISSIONS.PO_PROXY_READ,
        icon: <FormatListBulletedIcon />
      },
      {
        testId: 'survey-menu',
        label: 'Manage',
        link: '/latestSurveys',
        isActive: ['/latestSurveys'],
        permission: PERMISSIONS.SURVEY_CREATE,
        icon: <FormatListBulletedIcon />
      },
      {
        testId: 'survey-list',
        label: 'MySurveys',
        link: '/surveyList',
        isActive: ['/surveyList'],
        permission: PERMISSIONS.SURVEY_TAKE,
        permissionNotShow: [PERMISSIONS.PO_PROXY_READ],
        icon: <FormatListBulletedIcon />
      }
    ]
  },
  {
    testId: 'informationHub',
    label: 'informationHub',
    customMethodLink: (locale: string) => { window.open(`https://doc.agunity.com/fairinsight-${locale}`, '_blank'); },
    isActive: [], // ['/informationHub']
    icon: <BookIcon />
  },
  {
    testId: 'admin-tab',
    label: 'admin',
    icon: <AdminIcon />,
    isActive: [
      '/manageUsers',
      '/locales',
      '/activityRecords',
      '/manageOrganisations',
    ],
    permission: [PERMISSIONS.ADMIN_LOCALE, PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION],
    subMenu: [
      {
        testId: 'manageUsers-Admin',
        label: 'manageUsers',
        link: '/manageUsers',
        onPage: [/\/manageUsers\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'manageOrgs-Admin',
        label: 'manageOrganisations',
        link: '/manageOrganisations',
        onPage: [/\/manageUsers\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'activityRecords-Admin',
        label: 'activityRecords',
        link: '/activityRecords',
        onPage: [/\/activityRecords\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'locale-Admin',
        label: 'localeAdmin',
        link: '/locales',
        onPage: [/\/locales\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      }
    ]
  }
];

export default NavBar;
