import { FormattedMessage } from 'react-intl';
import { useMemo, useState } from 'react';
import { lazy, Suspense, useContext, useEffect } from 'react';
import styled from 'styled-components';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';

import { applyPerm } from '../SPOReport';
import { FigmaStyle } from 'styled';
import { ADD_REMOVE_FLOIDS_PERMISSION, PERMISSIONS } from 'appConstants';
import { selectCredentials } from '../login/redux';
import { selectProfile } from 'features/login/redux';
import { ThemeContext } from 'components/Theme/Context';
import { useAppSelector } from 'app/hooks';
import { useWithPrint } from './hooks';
import * as S from './styled';
import Container from './components/Container';
import HeadBar from './components/Headbar';
import NavBar from './components/NavBar';
import SPOReports from '../SPOReport';
import AddSurveyInstance from 'features/Survey/components/AddSurveyInstance';
import ConsentModalWrapper from '../../components/ConsentModalWrapper';

const Login = lazy(() => import('../login'));
const ForgotPassword = lazy(() => import('../forgotPassword'));
const HomePage = lazy(() => import('../HomePage'));
const ResetPassword = lazy(() => import('../resetPassword'));
const PrintReport = lazy(() => import('../SPOReport/screens/PrintReport'));
const PrintResults = lazy(() => import('../SPOReport/screens/PrintResults'));
const PrintFieldStaff = lazy(
  () => import('../SPOReport/screens/PrintFieldStaff')
);
const Profile = lazy(() => import('../profile'));
const ManageLocales = lazy(() => import('../Localisation'));
// const InformationHub = lazy(() => import('../InformationHub'));
// const Help = lazy(() => import('../InformationHub/screens/Help'));
// const HelpV2 = lazy(() => import('../InformationHub/screens/HelpV2'));
// const HelpResults = lazy(() => import('../InformationHub/screens/Results'));
// const Categories = lazy(() => import('../InformationHub/screens/Categories'));
// const Terms = lazy(() => import('../InformationHub/screens/Terms'));
// const Beneficiaries = lazy(() => import('../InformationHub/screens/Beneficiaries'));
const PDFTest = lazy(() => import('./components/PDFTest'));
const ManageUsers = lazy(() => import('../Administration/ManageUsers'));
const ManageOrgs = lazy(() => import('../Administration/ManageOrganisations'));
const ActivityRecords = lazy(() => import('../Administration/ActivityRecords'));
const Survey = lazy(() => import('../Survey'));
const SurveyList = lazy(() => import('../Survey/screens/SurveyList'));
const SurveyItem = lazy(() => import('../Survey/screens/SurveyItem'));

const GeolocationLinks = lazy(() => import('../OrganizationDetails/GeolocationLinks'));
const ManageGeolocationLinks = lazy(() => import('../OrganizationDetails/ManageGeolocationLinks'));

const NotFound = styled.div`
  width: 100%;
  padding-top: 4em;
  text-align: center;
`;

const NoRoute = () => (
  <>
    <NotFound>
      <FormattedMessage id='noPage' />
    </NotFound>
  </>
);

const authedRoutes = [
  '404',
  'report',
  'reports',
  'reportsList',
  'fieldStaff',
  'selectCategory',
  'details',
  'budget',
  'beneficiaries',
  'reviews',
  'done',
  'printReport',
  'printResults',
  'printFiltered',
  'locales',
  'profile',
  'informationHub',
  'pdftest',
  'manageUsers',
  'activityRecords',
  'latestSurveys',
  'takeSurvey',
  'surveyList',
  'surveyItem',
  'manageOrganisations',
  'organizationDetails',
  'geolocationLinks',
  '',
];
const unauthedRoutes = ['404', 'login', 'forgotPassword', 'resetPassword', ''];

function MyRedirect({ loggedIn }: { loggedIn: boolean }) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const slug = location.pathname;
    if (
      slug &&
      loggedIn &&
      slug.length > 1 &&
      !authedRoutes.find((route) => slug.includes(route))
    ) {
      console.log('re-route authed');
      return history.push('/404');
    }
    if (
      slug &&
      !loggedIn &&
      slug.length > 1 &&
      !unauthedRoutes.find((route) => slug.includes(route))
    ) {
      console.log('re-route unauthed');
      return history.push('/', { url: window.location.pathname });
    }
  }, [loggedIn, location, history]);

  return null;
}

export const Loader = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <S.Container theme={theme}>
      <S.Loader>
        <FormattedMessage id='loading' />
        ...
      </S.Loader>
    </S.Container>
  );
};

function MainRoutes({ creds }: { creds: any }) {
  const [toggleme, setToggle] = useState(1);
  const { isPrinting } = useWithPrint();
  const [showFull, setShowFull] = useState(false);
  const profile = useAppSelector(selectProfile);

  const toggle = () => {
    setToggle(toggleme + 1);
    if (toggleme % 2 === 0) {
      setShowFull(false);
    } else {
      setShowFull(true);
    }
  };

  return (
    <Switch>
      {!isPrinting ? (
        <S.Page>
          {process.env.REACT_APP_DEMO === '1' && (
            <S.DemoMessage>
              Fairinsight <span>DEMO</span>
            </S.DemoMessage>
          )}
          <S.Container>
            {toggleme % 2 === 0 ? null : <NavBar />}
            <S.RightSection>
              <HeadBar handleToggle={toggle} />
              <Container showFullWidth={showFull}>
                <Suspense fallback={<Loader />}>
                  <MyRedirect loggedIn={!!creds?.securityToken} />
                  <Route exact path='/404'>
                    <NoRoute />
                  </Route>
                  <Route path='/report'>
                    <SPOReports />
                  </Route>
                  <Route path='/reports'>
                    <SPOReports />
                  </Route>
                  <Route path='/reportsList'>
                    <SPOReports />
                  </Route>

                  <Route path='/fieldStaff'>
                    <SPOReports />
                  </Route>
                  <Route path='/locales'>
                    {applyPerm(
                      profile,
                      [PERMISSIONS.ADMIN_LOCALE, ADD_REMOVE_FLOIDS_PERMISSION],
                      <ManageLocales />
                    )}
                  </Route>
                  <Route path='/manageUsers'>
                    {applyPerm(
                      profile,
                      [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION],
                      <ManageUsers />
                    )}
                  </Route>
                  <Route path='/manageOrganisations'>
                    <ManageOrgs />
                  </Route>
                  <Route exact path='/organizationDetails/manage/geolocationLinks'>
                    {applyPerm(
                      profile,
                      [PERMISSIONS.FTI_ADMIN_READ, PERMISSIONS.FTI_ADMIN_WRITE],
                      <ManageGeolocationLinks />
                    )}
                  </Route>
                  <Route path='/activityRecords'>
                    <ActivityRecords />
                  </Route>
                  <Route path='/profile'>
                    <Profile />
                  </Route>
                  <Route exact path='/organizationDetails/geolocationLinks'>
                    {applyPerm(
                      profile,
                      [PERMISSIONS.GEOLINKS, PERMISSIONS.PO_WITH_GEOLINK, PERMISSIONS.USER_WITH_ORG_GEOLINK],
                      <GeolocationLinks />
                    )}
                  </Route>
                  <Route exact path='/latestSurveys'>
                    {applyPerm(profile, PERMISSIONS.SURVEY_CREATE, <Survey />)}
                  </Route>
                  <Route
                    path='/latestSurveysEdit/:id/:surveyId'
                    children={<AddSurveyInstance />}
                  />
                  <Route
                    path='/latestSurveys/:id'
                    children={<AddSurveyInstance />}
                  />
                  <Route exact path='/takeSurvey'>
                    {applyPerm(
                      profile,
                      PERMISSIONS.PO_PROXY_READ,
                      <SurveyList />
                    )}
                  </Route>
                  <Route exact path='/surveyList'>
                    {applyPerm(
                      profile,
                      PERMISSIONS.SURVEY_TAKE,
                      <SurveyList />
                    )}
                  </Route>
                  <Route path='/surveyItem/:id' children={<SurveyItem />} />
                  {/* <Route exact path='/informationHub'>
                    <InformationHub />
                  </Route>
                  <Route path='/informationHub/help'>
                    <Help />
                  </Route>
                  <Route path='/informationHub/helpv2'>
                    <HelpV2 />
                  </Route>
                  <Route path='/informationHub/beneficiaries'>
                    <Beneficiaries />
                  </Route>
                  <Route path='/informationHub/categories'>
                    <Categories />
                  </Route>
                  <Route path='/informationHub/terms'>
                    <Terms />
                  </Route>
                  <Route exact path='/informationHub/search/:term'>
                    <HelpResults />
                  </Route>
                  <Route exact path='/informationHub/search/'>
                    <HelpResults />
                  </Route> */}
                  <Route exact path='/'>
                    <HomePage />
                  </Route>
                </Suspense>
              </Container>
              <ConsentModalWrapper />
            </S.RightSection>
          </S.Container>
        </S.Page>
      ) : (
        <Suspense fallback={<Loader />}>
          {process.env.NODE_ENV === 'development' && (
            <Route path='/print/pdftest'>
              <PDFTest />
            </Route>
          )}
          <Route exact path='/printReport/:ids'>
            <PrintReport />
          </Route>
          <Route exact path='/printResults/:ids'>
            <PrintResults />
          </Route>
          <Route exact path='/printFiltered/:ids/:columns'>
            <PrintFieldStaff />
          </Route>
        </Suspense>
      )}
    </Switch>
  );
}

export default function App() {
  const creds = useAppSelector(selectCredentials);

  if (!creds?.securityToken) {
    return (
      <Router>
        <FigmaStyle />
        <MyRedirect loggedIn={!!creds?.securityToken} />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path='/'>
              <Login />
            </Route>
            <Route exact path='/forgotpassword'>
              <ForgotPassword />
            </Route>
            <Route exact path='/resetPassword'>
              <ResetPassword />
            </Route>
            <Route path='*'>
              <NoRoute />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    );
  }

  return (
    <Router>
      <div>
        <FigmaStyle />
        <MainRoutes creds={creds} />
      </div>
    </Router>
  );
}
