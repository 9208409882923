import axios from 'axios';
import { Api, GenericHttpResult } from '@agunity/api-v4';
import { AxiosHttpClient } from './axiosHttpClient';
import { apiUrl, isDevelop } from './environments';
import { SYSTEM_UNAVAILABLE_STATUS, UNAUTHORIZED } from 'appConstants';
import { store } from 'app/store';
import { expireCreds } from 'features/login/redux';

export interface ExtendedError extends Error {
  response?: {
    status: number;
    data: string;
  };
}

export interface ApiError { errorCode: number, description: string, errors: { [key: string]: any }[] };

export interface GenericApiError extends GenericHttpResult<ApiError> {}

class CommonDataManager {
  private static _apiService: Api<AxiosHttpClient>;
  private static _isAuthenticated: boolean = false;
  private static errorEvent?: <T>(err: Error) => GenericHttpResult<T>;

  /**
   * @returns {CommonDataManager}
   */
  static getApiInstance(): Api<AxiosHttpClient> {
    if (!CommonDataManager._apiService) {
      const apiClient = new AxiosHttpClient();
      apiClient.onError = (err: ExtendedError) => {
        if ((err?.response?.data as unknown as ApiError)?.errorCode === 25) {
          console.error('No License unavailable: ', err);
        } else if (err?.response?.status === SYSTEM_UNAVAILABLE_STATUS) {
          console.error('System unavailable: ', err);
        } else if (err?.response?.status === UNAUTHORIZED) {
          console.error('Unauthorized: ', err);
          store && store.dispatch(expireCreds());
        }
        
        if (CommonDataManager.errorEvent) {
          return CommonDataManager.errorEvent(err);
        }

        if (axios.isAxiosError(err)) {
          const status = Number(err.status);
          const { title, description: message, errors } = err.response?.data || {};
          let description = title || message || err?.message || '';
          if(status > 400) description = 'Server error';
          if(status === 204) description = 'data is empty';
          const errorsDetails = (Array.isArray(errors) && errors?.length > 0) ? errors.map((item) => (item?.detail)).join(', ') : description;
          throw { data: { ...err.response?.data, description, errorsDetails }, status: err.response?.status, headers: err.response?.headers };
        }

        throw err;
      };

      CommonDataManager._apiService = new Api<AxiosHttpClient>(apiClient);

      CommonDataManager._apiService.securityToken$.subscribe(token => {
        CommonDataManager._isAuthenticated = !!token;
      });
    }

    return CommonDataManager._apiService;
  }

  public static addErrorEvent(event: <T>(err: Error) => GenericHttpResult<T>) {
    CommonDataManager.errorEvent = event;
  }

  static get isAuthenticated() {
    return CommonDataManager._isAuthenticated;
  }
}

// Create an instance of the API class with your HTTP client
export const apiService: Api<AxiosHttpClient> = CommonDataManager.getApiInstance();
export const addErrorEvent = CommonDataManager.addErrorEvent;

export const isAuthenticated: () => boolean = () => CommonDataManager.isAuthenticated;

if (isDevelop) {
  console.log('api = ' + apiUrl);
}