import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ConsentState } from 'appConstants';
import AsyncButton from 'components/AsyncButton';
import CheckboxComponent from 'components/Checkbox';
import Modal from 'components/Modal';
import SuccessModal from 'components/shared/SuccessModal';
import { fetchProfile } from 'features/login/redux';
import { fetchOrganisation } from 'features/SPOReport/redux';
import { ReactElement, useState } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import rehypeSlug from 'rehype-slug';
import remarkToc from 'remark-toc';
import { ConsentStateItem } from 'types';
import { updateConsentState } from '../redux';

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: ConsentStateItem;
  open: boolean;
  onClose: (value?: string) => void;
  currentIndex: number;
  setCurrentIndex: Function;
  handleAccept: Function;
  handleDecline: Function;
  consentLength?: number;
}

function ConsentModal(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, currentIndex, setCurrentIndex, handleDecline, handleAccept, consentLength, ...other } = props;
  const [value, setValue] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    handleAccept();
  };

  const confirmFooter: ReactElement = (
    <div className='row'>
      <div className='col' style={{ minWidth: '8em' }}>
        <AsyncButton
          messageId='continue'
          onClick={handleCloseSuccessModal}
          isBlue
          testId='continue'
        />
      </div>
    </div>
  );

  function setShowModal() {}

  const handleEntering = () => {
    // if (radioGroupRef.current != null) {
    //   radioGroupRef.current.focus();
    // }
  };

  const handleCancel = () => {
    onClose();
    setChecked(false);
  };

  const handleOk = async () => {
    setShowSuccessModal(true);
    onClose(valueProp?.description);
    setChecked(false);
    setIsAgreed(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Agreed));
  };

  const handleDisagree = async () => {
    setIsAgreed(false);
    setShowSuccessModal(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Rejected));
  };

  const handleAgree = async () => {
    setIsAgreed(true);
    setShowSuccessModal(true);
    await dispatch(updateConsentState(valueProp.id, ConsentState.Agreed));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const renderContent = () => {
    return (
      valueProp && (
        <ReactMarkdown
          children={valueProp?.description}
          remarkPlugins={[remarkToc]}
          rehypePlugins={[rehypeSlug]}
        />
      )
    );
  };

  return (
    <>
      <Dialog
        maxWidth='sm'
        onEntering={handleEntering}
        aria-labelledby='confirmation-dialog-title' 
        data-testid='consent-modal'
        data-locale={valueProp?.locale}
        data-consentLength={consentLength}
        data-type={valueProp.testType}
        open={open}
        {...other}
      >
        <DialogTitle id='confirmation-dialog-title'>
          <FormattedMessage id='GeneralTerms' />
        </DialogTitle>
        <DialogContent dividers>{renderContent()}</DialogContent>
        {valueProp?.isMandatory ? (
          <div className='row mt-2' style={{ margin: 'unset' }}>
            <CheckboxComponent
              testId='consent-checkbox'
              handleChange={() => {
                setChecked(!checked);
              }}
              checked={checked}
            />
            <div style={{ padding: '9px' }}>
              <FormattedMessage id='AcceptConditions' />
            </div>
          </div>
        ) : (
          <div style={{ padding: '10px' }}>
            <FormattedMessage id='consentDesc' />
          </div>
        )}
        <DialogActions
          style={
            valueProp?.isMandatory
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'space-between' }
          }
        >
          {valueProp?.isMandatory ? (
            <AsyncButton
              testId='consent-confirm-button'
              onClick={handleOk}
              isBlue
              disabled={!checked}
              messageId='continue'
            />
          ) : (
            <>
              <AsyncButton
                testId='consent-disagree-button'
                onClick={handleDisagree}
                isBlue
                messageId='IDisagree'
              />
              <AsyncButton onClick={handleAgree} isBlue messageId='IAgree' />
            </>
          )}
        </DialogActions>
      </Dialog>
      {valueProp?.isMandatory ? (
        <SuccessModal
          show={showSuccessModal}
          successMessageId2={'TermsPage'}
          successTitle={'CanAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      ) : isAgreed ? (
        <SuccessModal
          show={showSuccessModal}
          successMessageId={'canRevoke'}
          successMessageId2={'contactEmail1'}
          successMessageId3={'contactEmail2'}
          successMessageId4={'contactEmail3'}
          successTitle={'continueToAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      ) : (
        <SuccessModal
          show={showSuccessModal}
          successMessageId={'canRevoke'}
          successMessageId2={'notShare'}
          successMessageId3={'giveIn6Month'}
          successTitle={'continueToAccess'}
          setShow={setShowSuccessModal}
          footer={confirmFooter}
          handleClose={handleCloseSuccessModal}
          style={{ textAlign: 'left' }}
        />
      )}
    </>
  );
}

export default ConsentModal;
