export enum PLAN_SCREEN_SECTION {
  Draft = 'draft' ,
  Approved = 'approved',
  Past = 'past'
}

export enum PLAN_STATUS {
  Draft = 0,
  Approved = 1,
  New = 2,
  Deleted = 3,
  /** Phase 2 Change: ApprovedResults is now known as Blank1 */
  ApprovedResults = 4,
  NotesComplete,
  NotReviewed,
  Reviewed,
  Blank2,
  ReviewComments,
  CommentsChecked,
  ChangesMade,
  Plausible
}

export enum SURVEY_RESULT_STATUS {
  NotStarted = 0,
  Started = 1,
  Submitted = 2,
  Closed = 3
}

export enum SURVEY_STATUS {
  Draft = 0,
  Scheduled = 1,
  Open = 2,
  Closed = 3
}

export enum WRAPPER_STATUS {
  Draft,
  Approved,
  QuantitativeReview = 4,
  QualitativeReview,
  POReview,
  Plausible,
  ReviewClosed
}

export enum FILTER_WRAPPER_STATUS {
  IncompleteDrafts = 119,
  CompleteDrafts,
  IncompleteResults,
  CompleteResults,
  All,
  FTA_Review = 1995
}

export const WRAPPER_STATUS_LABEL: {
  [key in WRAPPER_STATUS | FILTER_WRAPPER_STATUS]: string;
} = {
  [WRAPPER_STATUS.Draft]: 'Draft',
  [WRAPPER_STATUS.Approved]: 'Approved',
  [WRAPPER_STATUS.QuantitativeReview]: 'Quantitative Review',
  [WRAPPER_STATUS.QualitativeReview]: 'Qualitative Review',
  [WRAPPER_STATUS.POReview]: 'PO Review',
  [WRAPPER_STATUS.Plausible]: 'Plausible',
  [WRAPPER_STATUS.ReviewClosed]: 'Review Closed',
  [FILTER_WRAPPER_STATUS.IncompleteDrafts]: 'Incomplete Drafts',
  [FILTER_WRAPPER_STATUS.CompleteDrafts]: 'Complete Drafts',
  [FILTER_WRAPPER_STATUS.IncompleteResults]: 'Incomplete Results',
  [FILTER_WRAPPER_STATUS.CompleteResults]: 'Complete Results',
  [FILTER_WRAPPER_STATUS.All]: 'All',
  [FILTER_WRAPPER_STATUS.FTA_Review]: 'FTA Review'
};

export const PLAN_STATUS_LABEL = {
  0: 'draft',
  1: 'inProgress',
  2: 'new',
  3: 'deletedDrafts',
  4: 'pastPlans',
  5: 'To be populated',
  6: 'Undeleted',
  7: 'CompleteQuantitative',
  8: 'FinishedReview',
  9: 'POReview',
  10: 'Plausible',
  11: 'ResubmitQualitative',
  12: 'To be populated'
};

export const NEW_PLAN_STATUS_LABEL: {
  [key in Exclude<PLAN_STATUS, 0 | 1 | 2 | 3>]: string;
} = {
  [PLAN_STATUS.ApprovedResults]: '-',
  [PLAN_STATUS.NotesComplete]: 'Notes Complete',
  [PLAN_STATUS.NotReviewed]: 'Not Reviewed',
  [PLAN_STATUS.Reviewed]: 'Reviewed',
  [PLAN_STATUS.Blank2]: '-',
  [PLAN_STATUS.ReviewComments]: 'Review Comments',
  [PLAN_STATUS.CommentsChecked]: 'Comments Checked',
  [PLAN_STATUS.ChangesMade]: 'Changes Made',
  [PLAN_STATUS.Plausible]: 'Plausible'
};

export const PLAN_BY_DESC = {
  draft: 0,
  approved: 1,
  new: 2,
  deleted: 3,
  past: 4
};

export const PLAN_BY_DESC_2 = {
  draft: 0,
  approved: 1,
  new: 2,
  deleted: 3,
  past: 4
};

export enum PLAN_ACTION {
  Inserted = 0,
  Updated = 1,
  Shared = 2,
  Approved = 3,
  Result = 4,
  Delete = 5,
  Undeleted = 6,
  CompleteQuantitative = 7,
  FinishedReview = 8,
  POReview = 9,
  Plausible = 10,
  ResubmitQualitative = 11
}

export const PLAN_ACTION_LABEL = {
  0: 'inserted',
  1: 'updated',
  2: 'shared',
  3: 'approved',
  4: 'result',
  5: 'delete',
  6: 'Undeleted',
  7: 'CompleteQuantitative',
  8: 'FinishedReview',
  9: 'POReview',
  10: 'Plausible',
  11: 'ResubmitQualitative'
};

export const FEATURE_FLAGS = {
  0: 'Yield',
  1: 'FilterSelector',
  2: 'Certificate',
  3: 'StaffOrgs',
  4: 'UploadMemberImage',
  5: 'EditTranslations',
  6: 'Insight-ReportIssue', // Support Feature
  7: 'CODImpact', // Producer Data
  8: 'Investment.Clone',
  9: 'Consents.Management',
};

export enum PERMISSIONS {
  CREATE = 1065,
  VIEW = 1066,
  APPROVE_PLAN = 1067,
  ENTER_RESULTS = 1068,
  APPROVE_RESULTS = 1069,
  EXPORT = 1070,
  SHARE = 1071,
  DELETE = 1072,
  ADMIN_LOCALE = 1073,
  SHARED_VIEW = 1074,
  UPDATE_ORG = 1075,
  FIELD_STAFF_VIEW = 1076,
  PROXY_PLANNER = 1089,
  ADMIN_USERS = 1090,
  SURVEY_CREATE = 1116,
  SURVEY_TAKE = 1117,
  QUALITATIVE_REVIEW = 1118,
  QUANTITATIVE_REVIEW = 1119,
  RESTORE = 1121,
  UNAPPROVE = 1122,
  PN = 42,
  FI = 43,
  PO_PROXY_READ = 56,
  PO_PROXY_WRITE = 58,
  ADD_ORG = 16,
  GEOLINKS = 56,
  FTI_ADMIN_WRITE = 63,
  FTI_ADMIN_READ = 64,
  PO_USER = 573, // Code for PO users (80+79+95+85+83+69+82 = 573) === PO_USER "https://www.cs.cmu.edu/~pattis/15-1XX/common/handouts/ascii.html", "https://www.duplichecker.com/text-to-ascii.php"
  PO_WITH_GEOLINK = 1186, // Code for PO users that have GeoLink (80+79+95+87+73+84+72+95+71+69+79+76+73+78+75 = 1186) === PO_WITH_GEOLINK "https://www.cs.cmu.edu/~pattis/15-1XX/common/handouts/ascii.html", "https://www.duplichecker.com/text-to-ascii.php"
  USER_WITH_ORG_GEOLINK = 1673, // Code for PN users that have GeoLink (85+83+69+82+95+87+73+84+72+95+79+82+71+95+71+69+79+76+73+78+75 = 1673) === USER_WITH_ORG_GEOLINK "https://www.cs.cmu.edu/~pattis/15-1XX/common/handouts/ascii.html", "https://www.duplichecker.com/text-to-ascii.php"
  ACCOUNT_SET_PASSWORD = 66,
  CODIMPACT_READ = 1127,
  CODIMPACT_CURRENT_ORG_WRITE = 1128,
  CODIMPACT_CHILD_ORG_WRITE = 1129,
}

export enum FILE_TYPES {
  PLAN = 46,
  APPROVAL = 47,
  MINUTES = 48,
  RESULT = 49,
  RELEASE = 50,
  ORGANISATION = 1,
  TESTIMONIAL = 51,
  PLANWRAPPER = 57,
  APPROVERESULTWRAPPER = 58,
  ExtSurvey = 65,
  ExtSurveyInstance = 66
}

export enum FILE_TYPES_ATTACH {
  PLAN = 46,
  APPROVAL = 47,
  MINUTES = 48,
  RESULT = 49,
  'Release Forms' = 50,
  ORGANISATION = 1,
  TESTIMONIAL = 51,
  'Draft Approval' = 57,
  'Results Approval' = 58
}

export enum APPROVERS {
  GENERAL_ASSEMBLY = 1,
  BOARD = 2,
  OTHER = 3
}

export enum ITEM_TYPES {
  ORGANISATION = 1,
  USER = 2
}

export enum MESSAGE_ITEM_STATUS {
  Unread,
  Read,
  Hidden
}

/*
 * SAME WORD `PO` for both PO Member and PO Proxy role holder
 */
export enum USER_CATEGORY {
  PO,
  MEL,
  FS
}

export const USER_CATEGORIES = {
  [USER_CATEGORY.PO]: 'PO',
  [USER_CATEGORY.MEL]: 'MEL',
  [USER_CATEGORY.FS]: 'Field Staff'
};

export const COMMENT_PERMISSIONS: {
  [key in WRAPPER_STATUS]: { view: USER_CATEGORY[]; add: USER_CATEGORY[] };
} = {
  [WRAPPER_STATUS.Draft]: {
    view: [],
    add: []
  },

  [WRAPPER_STATUS.Approved]: {
    view: [],
    add: []
  },

  [WRAPPER_STATUS.QuantitativeReview]: {
    view: [USER_CATEGORY.MEL],
    add: [USER_CATEGORY.MEL]
  },

  [WRAPPER_STATUS.QualitativeReview]: {
    view: [USER_CATEGORY.MEL, USER_CATEGORY.FS],
    add: [USER_CATEGORY.MEL, USER_CATEGORY.FS]
  },

  [WRAPPER_STATUS.POReview]: {
    view: [USER_CATEGORY.PO],
    add: [USER_CATEGORY.PO]
  },

  //TODO: Confirm the Permissions Check for ReviewClosed & Plausible
  [WRAPPER_STATUS.Plausible]: {
    view: [USER_CATEGORY.PO],
    add: []
  },

  [WRAPPER_STATUS.ReviewClosed]: {
    view: [USER_CATEGORY.PO],
    add: []
  }
};

export enum ConsentState {
  Blank = 0,
  Agreed = 1,
  Rejected = 2
}

export enum ConsentDefinitionNames {
  UserConsent = 'User T&C',
  OrgConsent = 'Org Consent',
}

export enum COMMENT_READ_REASON {
  ALL_READ,
  ALL_RESOLVED
}

export enum ActiveStatus {
  Active = 0,
  Disabled = 1,
  Deleted = 2
}

export const APPROVERS_LABEL = {
  1: 'General Assembly',
  2: 'Board',
  3: 'FT Premium Committee'
};

export const GRAPH_COLORS = [
  '#1F2B3D',
  '#0EC0FC',
  '#C7D04A',
  '#FFAA48',
  '#7B1DAB',
  '#FF4848'
];

export type EXPORT_TYPES = {
  ORIGINAL_PLAN;
  RESULTS;
};

export type REPORT_AUDIENCE_TYPES = {
  EXTERNAL_MARKETING;
  INTERNAL;
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// export const LOCALES = { // todo plz delete locales after testing, all languages should come from API 'Language/V2.0/Locales/fairtrade'
//   english: 'en',
//   spanish: 'es',
//   french: 'fr',
//   portuguese: 'pt',
//   indonesian: 'id',
//   chinese: 'zh',
//   russian: 'ru',
//   thailand: 'th',
//   tagalog: 'tl',
// };

export const PRODUCE_MAP = {
  633: 'Cacao',
  303: 'Coffee',
  634: 'Rose',
  371: 'Honey',
  635: 'Quinoa',
  636: 'Wine',
  637: 'Nuts',
  563: 'Vegetables',
  359: 'vanilla',
  638: 'Tea',
  639: 'Cotton',
  640: 'Fresh Fruit',
  370: 'Sugar Cane',
  641: 'Oil Seed',
  642: 'Spices',
  561: 'Banana',
  643: 'Cereals',
  431: 'Orange',
  644: 'Papaya',
  368: 'Avocado',
  645: 'Flowers',
  310: 'Sugar',
  646: 'Oil Seed and Fruits'
};

export const BUDGET_MAX_AMOUNT = 15000000000;
export const BUDGET_MAX_AMOUNT_DECIMAL = 99;
export const ADD_REMOVE_FLOIDS_PERMISSION = 56;
export const ArchiveActiveStatus_Inactive = 1;
export const ArchiveActiveStatus_Active = 0;
export const deleteTargetFloIdList = ['-1'];
export const LanguageSupportedForProductTree = ['en', 'es', 'fr', 'pt']; // only these 4 locales are supported for product tree
export const Locale_English = 'en';

export const SUCCESS_STATUS = 200;
export const SYSTEM_UNAVAILABLE_STATUS = 404;
export const UNAUTHORIZED = 401;
export const APP_NAME = 'FI';
export const CLIENT_NAME = 'fairtrade';
export const TEMPLATE_NAME = 'Insight-ResetPassword';
export const TARGET = 'Insight';

/**
 * ID assigned to a new and unsaved investment that is being created on the client side.
 * For new and unsaved investments: report.id = NEW_INVESTMENT_ID;
 */
export const NEW_INVESTMENT_ID = -1;

export enum API_LOG_LEVEL {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6,
}

export const COD_STATUS = {
  1: 'Open',
  2: 'Historic', // Closed
};

export const PRODUCER_SETUPS = [
  { id: '1', orgType: 'SPO' }, // Miner - ASMO
  { id: '2', orgType: 'SPO' }, // Producer - CP - SCORE
  { id: '3', orgType: 'HL' }, // Producer - HL - Multiestore SCORE
  { id: '4', orgType: 'HL' }, // Producer - HL - Single Plantation SCORE
  { id: '5', orgType: 'SPO' }, // Producer - SPO - 1st grade SCORE
  { id: '6', orgType: 'SPO' }, // Producer - SPO - 2nd grade SCORE
  { id: '7', orgType: 'SPO' }, // Producer - SPO - 3rd grade SCORE
];
