import { useState, useContext, ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { doLogout, selectProfile } from 'features/login/redux';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {
  AppBar,
  Badge,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Toolbar
} from '@material-ui/core';

import { LanguageContext } from 'components/Language/Context';
import { DirtyContext } from 'components/Dirty/Context';
import { useAppSelector } from 'app/hooks';
import * as S from './Headbar.styled';
import LangSelect from 'components/Language/Selector';
import Modal from 'components/Modal';

import account_B from 'assets/icons/account_B.svg';
import account_G from 'assets/icons/account_G.svg';
import user from 'assets/icons/user.svg';
import _logout from 'assets/icons/_logout.svg';
import notification from 'assets/icons/bell.svg';
import helpDocsIcon from 'assets/icons/helpDocs.svg';
import helpSupportIcon from 'assets/icons/helpSupport.svg';
import questionMark from 'assets/icons/help.svg';
import questionMarkActive from 'assets/icons/helpActive.svg';
import CurrencyDropdown from 'components/CurrencyDropdown/CurrencyDropdown';
import SurveySaveExitButton from '../../Survey/components/SurveySaveExitButton';
import SurveyHeadbar from '../../Survey/components/SurveyHeadbar';
import { selectFeatureFlags } from 'features/Administration/redux';
import { PERMISSIONS, FEATURE_FLAGS } from 'appConstants';

type State = {
  search: string;
};

function HeadBar({ handleToggle }) {
  const { locale } = useContext(LanguageContext);
  const [to, setTo] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [toggeledState, setToggeledState] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const profile = useAppSelector(selectProfile);
  const { isDirty, setDirty } = useContext(DirtyContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const featureFlags = useAppSelector(selectFeatureFlags);
  const hasSupportFeature = (featureFlags || []).includes(FEATURE_FLAGS[6]);

  const wrapperType = localStorage.getItem('selectedWrapperType');
  const wrapperYear = localStorage.getItem('selectedwrapperYear');
  const wrapperID = localStorage.getItem('selectedwrapperId');
  const cachedLangSelect = useMemo(() => <LangSelect />, []);

  // Only show the breadcrumb if the pathname contains at least three segments. 
  const pathname = location.pathname.split('/').filter(Boolean);
  const showBreadcrumb = pathname.length >= 3 && localStorage.getItem('selectedWrapperType') !== null;

  const loginSlice = useSelector((state: any) => state.login);
  const userPermissions = useMemo(() => ({
    PO_PROXY_READ: loginSlice?.profile?.permissions?.some((code: number) => (code === PERMISSIONS.PO_PROXY_READ))
  }), [loginSlice?.profile]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const link = (to: string) => {
    handleMenuClose();
    history.push(to);
  };

  const logout = () => {
    dispatch(doLogout());
    handleMenuClose();
    setTimeout(() => history.push('/'), 100);
  };

  const doLink = (toLink: string) => {
    if (isDirty) {
      setTo(toLink);
      setShowModal(true);
      return;
    }
    link(toLink);
  };

  const helpMenuId = 'help-menu';
  const renderHelpMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={`${helpMenuId}-popover`}
      keepMounted
      open={isMenuOpen && anchorEl?.id === helpMenuId}
      onClose={handleMenuClose}
    >
      <MenuItem data-testid='user-guidance' style={{ padding: '0.6em 1em' }} onClick={() => {
        window.open(`https://doc.agunity.com/fairinsight-${locale}`, '_blank');
        handleMenuClose();
      }}>
        <S.SVG_Icon src={helpDocsIcon} />
        &nbsp;&nbsp;
        <S.MenuItem className='action'>
          <FormattedMessage id='informationHub' />
        </S.MenuItem>
      </MenuItem>
      {hasSupportFeature && !userPermissions.PO_PROXY_READ && (
        <MenuItem data-testid='report-an-issue' style={{ padding: '0.6em 1em' }} onClick={() => {
          window.open('https://form.asana.com/?k=CNLLrY0tXGME7R33L_tMpw&d=483532170256100', '_blank');
          handleMenuClose();
        }}>
          <S.SVG_Icon src={helpSupportIcon} />
          &nbsp;&nbsp;
          <S.MenuItem className='action'>
            <FormattedMessage id='reportAnIssue' />
          </S.MenuItem>
        </MenuItem>
      )}
    </Popover>
  );

  const userMenuId = 'user-menu';
  const renderUserMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={`${userMenuId}-popover`}
      keepMounted
      open={isMenuOpen && anchorEl?.id === userMenuId}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} style={{ marginTop: '0.5em' }}>
        <S.MenuItem>
          <FormattedMessage id='welcome' />, {profile?.firstName}{' '}
          {profile?.familyName}!
        </S.MenuItem>
      </MenuItem>
      <Divider
        variant='middle'
        style={{ marginTop: '0.3em', marginBottom: '0.3em' }}
      />
      <MenuItem data-testid='profile' onClick={() => doLink('/profile')}>
        <S.SVG_Icon src={user} />
        &nbsp;&nbsp;
        <S.MenuItem className='action'>
          <FormattedMessage id='profile' />
        </S.MenuItem>
      </MenuItem>
      <MenuItem data-testid='logout' onClick={() => logout()}>
        <S.SVG_Icon src={_logout} />
        &nbsp;&nbsp;
        <S.MenuItem className='action'>
          <FormattedMessage id='logout' />
        </S.MenuItem>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        style={{
          marginTop: '-0.4em',
          marginLeft: '-0.4em',
          marginRight: '-0.4em'
        }}
      >
        {cachedLangSelect}
      </MenuItem>
    </Popover>
  );

  const body: ReactElement = (
    <Row>
      <Col>
        <FormattedMessage id='dirtyConfirm' />
      </Col>
    </Row>
  );

  const footer: ReactElement = (
    <Row>
      <Col>
        <Button onClick={() => setShowModal(false)} variant='dark'>
          <FormattedMessage id='cancel' />
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            setDirty(false);
            setShowModal(false);
            link(to);
          }}
          variant='success'
        >
          <FormattedMessage id='ok' />
        </Button>
      </Col>
    </Row>
  );

  return (
    <S.Container $isToggled={toggeledState}>
      {showModal && (
        <Modal
          show={showModal}
          handleClose={() => setShowModal(false)}
          title={<FormattedMessage id='isDirty' />}
          body={body}
          footer={footer}
          size='lg'
        />
      )}
      <S.Grow>
        <AppBar
          position='static'
          color='inherit'
          style={{ height: '3em', boxShadow: 'none' }}
        >
          <Toolbar style={{ minHeight: '3em' }}>
            {showBreadcrumb && (
              <>
                <span
                  onClick={() => {
                    history.push(`/reportsList/${wrapperType}`);
                    localStorage.removeItem('selectedWrapperType');
                    // localStorage.removeItem('selectedwrapperId');
                    localStorage.removeItem('selectedWrapperYear');
                  }}
                  style={{
                    display: 'inline-flex',
                    fontWeight: 'normal',
                    color: '#3284FB',
                    cursor: 'pointer'
                  }}
                >
                  {wrapperType?.charAt(0).toUpperCase()}
                  {wrapperType?.slice(1)} Plans
                </span>

                <S.Dot></S.Dot>

                <span
                  onClick={() => {
                    history.push(`/reportsList/${wrapperType}/${wrapperID}`);
                    localStorage.removeItem('selectedWrapperType');
                    // localStorage.removeItem('selectedwrapperId');
                    localStorage.removeItem('selectedWrapperYear');
                    // window.location.reload();
                  }}
                  style={{
                    display: 'inline-flex',
                    marginLeft: '2em',
                    fontWeight: 'normal',
                    color: '#3284FB',
                    cursor: 'pointer'
                  }}
                >
                  {wrapperYear} Plan Summary
                </span>
              </>
            )}
            <SurveySaveExitButton />
            <S.Grow2 />
            <S.SectionDesktop>
              {location.pathname.toLowerCase().includes('reportslist/past') && (
                <CurrencyDropdown setSelectedCurrencyx={() => {}} />
              )}
              <IconButton
                aria-label='show 17 new notifications'
                color='inherit'
                onClick={() => {
                  doLink('/');
                }}
              >
                <Badge color='secondary'>
                  <S.SVG_Icon src={notification} />
                </Badge>
              </IconButton>

              <IconButton
                id={helpMenuId}
                aria-label='help documentation'
                aria-controls={helpMenuId}
                aria-haspopup='true'
                onClick={handleMenuOpen}
              >
                {(isMenuOpen && anchorEl?.id === helpMenuId) ? (
                  <S.SVG_Icon src={questionMarkActive} />
                ) : (
                  <S.SVG_Icon src={questionMark} />
                )}
              </IconButton>

              <IconButton
                data-testid={'headBar-userMenu-button'}
                id={userMenuId}
                edge='end'
                aria-label='account of current user'
                aria-controls={userMenuId}
                aria-haspopup='true'
                onClick={handleMenuOpen}
              >
                {((isMenuOpen && anchorEl?.id === userMenuId) || location.pathname.includes('/profile')) ? (
                  <S.SVG src={account_G} />
                ) : (
                  <S.SVG src={account_B} />
                )}
              </IconButton>
            </S.SectionDesktop>
          </Toolbar>
        </AppBar>
        {renderHelpMenu}
        {renderUserMenu}
      </S.Grow>
      <SurveyHeadbar />
    </S.Container>
  );
}

export default HeadBar;
