import MediaQuery from 'react-responsive';
import { useContext } from 'react';

import App from 'features/page';
import { PrintContext } from 'components/Printing/Context';

function Container() {
  const { isPrinting } = useContext(PrintContext);

  if (isPrinting) return <App />;

  if (process.env.NODE_ENV === 'test') return <App />;

  return (
    <>
      <MediaQuery minWidth={800}>
        <App />
      </MediaQuery>
      <MediaQuery maxWidth={799}>
        <div>
          This application is desktop based, please load in a desktop browser.
        </div>
      </MediaQuery>
    </>
  );
}

export default Container;
