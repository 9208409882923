import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import * as S from './AddSurveyInstance.styled';
import * as G from 'styled';
import { Row } from 'react-bootstrap';
import { LanguageContext } from 'components/Language/Context';
import TextInput from 'components/TextInput';
import { ErrorsContext } from 'components/Errors/Context';
import { fdate2 } from 'utils';
import { useToast } from 'features/SPOReport/hooks';
import arrowIcon from 'assets/icons/arrow.svg';
import ActionButtons from './ActionButtons';
import backarrow from 'assets/icons/backarrow_.svg';
import AsyncButton from 'components/AsyncButton';
import { Button } from '@mui/material';
import Modal from 'components/Modal';
import { useHistory } from 'react-router';
import {
  ableToSubmit,
  DATE_CONSTRAINTS,
  validateDate,
} from 'features/SPOReport/validation';
import {
  selectAddSurveyInstance,
  addSurveyInstance as setAddSurveyInstance,
  getSurveyInstances,
  getSurveysAndInstances,
  getTargetList
} from '../redux';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DirtyContext } from 'components/Dirty/Context';
import { debounce, isEmpty } from 'lodash';
import { useParams } from 'react-router';
import { selectCountries } from '../../Administration/redux';
import { deleteTargetFloIdList, SURVEY_STATUS } from '../../../appConstants';
import SurveyFrequency from './SurveyFrequency';
import { IResponse } from 'types';

const PER_PAGE = 10;

const AddSurveyInstance = () => {
  const { formatMessage } = useIntl();
  const survey = useAppSelector(selectAddSurveyInstance);
  const countries = useAppSelector(selectCountries);
  const { messages } = useContext(LanguageContext);
  const [errors, setErrors] = useState<any>({});
  const [description, setDescription] = useState('');
  const { toast } = useToast();
  const [sort, setSort] = useState<any>({ what: 'lastLogon', how: 'asc' });
  const [errorStatus, seterrorStatus] = useState<boolean>(false);
  const [hasSurveys, setSurveys] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [newSurveyUpload, setnewSurveyUpload] = useState<any>();
  const [isPublish, setPublish] = useState<boolean>(true);
  const history = useHistory();
  const isEditSurvey = history.location.pathname.startsWith('/latestSurveysEdit');
  const [todayDate, setToday] = useState<any>();
  const [targetOrgs, setTargetOrgs] = useState<string[]>([]);
  const [invalidTargetOrgs, setInvalidTargetOrgs] = useState<string[]>([]);
  const [state, setState] = useState<any>({
    startDate: '',
    endDate: '',
    description: '',
    operationalName: '',
    floid: '',
    country: '',
    orgType: [],
    today: '',
    isDraft: true
  });
  // draft and schedule survey can delete list
  const isShowingDeleteListButton = hasSurveys && (!state?.status || state?.status <= SURVEY_STATUS.Scheduled);
  // 'publish' btn is disabled if there is no date, or no name, or no survey, or already published (isDraft is false)
  const isPublishDisabled = !(
    state.startDate &&
    state.endDate &&
    state.frequency &&
    state.description &&
    hasSurveys &&
    state?.isDraft
  );
  const dispatch = useAppDispatch();
  const {
    errors: contextErrors,
    hasError,
    removeError,
  } = useContext(ErrorsContext);
  const { isDirty, setDirty, setShowModal, setLinkTo } = useContext(DirtyContext);
  const id: { id?: string | number; surveyId?: string } = useParams();
  const [isLoading_TargetRespondent, setIsLoading_TargetRespondent] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<{ show: boolean, callback?: () => void }>({ show: false });

  useEffect(() => {
    const getFullList = async () => {
      if (!id?.surveyId) return;
      setIsLoading_TargetRespondent(true);
      try {
        const response = await dispatch(getSurveysAndInstances());
        // response.data could be an empty {}
        if (response.data) {
          const survey = response.data.find(
            (ins) => ins?.id === Number(id?.id)
          );
          if (survey) {
            const instance = survey?.instances?.find(
              (ins) => ins?.id === Number(id?.surveyId)
            );
            if (instance) {
              setState((prev) => ({
                startDate:
                  instance?.startDate?.split('T')?.[0] ?? prev.startDate,
                endDate: instance?.endDate?.split('T')?.[0] ?? prev.endDate,
                description: instance?.name ?? prev.description,
                status: instance?.status ?? 0,
                isDraft: instance?.isDraft,
                frequency: instance?.reminderFreq ?? 0
              }));
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      try {
        // below is calling API sequentially, may need Stefan's help to have a new API
        const response = await dispatch(getTargetList(Number(id?.surveyId)));
        if (response.data && response.data.length) {
          const floidArray = response.data.map((item) => item.floid);
          const result = await dispatch(getSurveyInstances(floidArray));
          if (result.data) {
            setnewSurveyUpload(result.data);
            setSurveys(true);
            setPublish(false);

            // Include the floIds in payload #5457
            const floIds = (result.data || []).map(({ floid }) => floid);
            setTargetOrgs(floIds as string[]);
          }
        } else {
          setnewSurveyUpload([]);
        }
      } catch (e) {
        console.error(e);
      }
      setIsLoading_TargetRespondent(false);
    };
    getFullList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id.surveyId]);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd;
    setState((state) => ({
      ...state,
      today: date,
    }));
    // state.today = date;
    setToday(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const addDate = (current: string, add: number) => {
    const currentDate = new Date(current).getDate();
    const addedDate = currentDate + add;
    return fdate2(new Date(new Date(current).setDate(addedDate)));
  };

  const handleDelete = () => {
    setTargetOrgs((isEditSurvey) ? deleteTargetFloIdList : []);
    setInvalidTargetOrgs([]);
    setSurveys(false);
    setnewSurveyUpload('');
    setFileName('');
    setPublish(true);
  };

  const set = async (key: string, value: string | number) => {
    const newSurvey = { ...state, [key]: value };
    setState(newSurvey);
    setDirty(true);
    if (key === 'description' && value && hasError('details', 'description')) {
      removeError('details', 'description');
    }
    await debounce(
      (newReport) => dispatch(setAddSurveyInstance(newSurvey)),
      500
    )(newSurvey);
  };

  const handleInvestmentDates = (event: FocusEvent): void => {
    const target = event.target as HTMLInputElement;
    const type = target.id === 'investmentStartDate' ? 'startDate' : 'endDate';

    if (
      (type === 'startDate' && state.endDate && target.value > state.endDate) ||
      (type === 'endDate' &&
        state.startDate &&
        target.value < state.startDate) ||
      (state.startDate && state.endDate && state.startDate > state.endDate)
    )
      target.value = '';

    const year = parseInt(target.value.split('-')[0]);
    if (isNaN(year)) {
      set(type, '').then(() => {
        toast('Invalid date!', 'error');
        setErrors({ ...errors, [type]: true });
        target.value = '';
      });
    } else {
      if (errors.hasOwnProperty(type)) {
        setErrors({ ...errors, [type]: false });
      }
    }

    if (!state[type]) {
      return;
    }
    const enteredDate = new Date(state[type] || '');
    const minDate = new Date(target.min || DATE_CONSTRAINTS.MIN_DATE);
    const maxDate = new Date(target.max || DATE_CONSTRAINTS.MAX_DATE);
    const { valid: isDateCorrect, msg: errorMsg } = validateDate(
      enteredDate,
      maxDate,
      minDate
    );
    if (!isDateCorrect) {
      set(type, '').then(() => {
        toast(errorMsg, 'error');
        setErrors({ ...errors, [type]: true });
        target.value = '';
        target.focus();
      });
    } else {
      if (errors.hasOwnProperty(type)) {
        setErrors({ ...errors, [type]: false });
      }
    }
  };

  const sortable = (what: string, mId: string, width: string | null) => {
    return (
      <G.Sortable
        onClick={() => {
          setSort({ what, asc: what !== sort.what ? true : !sort?.asc });
        }}
        style={{ width: width || '' }}
      >
        <FormattedMessage id={mId} />
        {sort.what === what ? (
          sort.asc ? (
            <img src={arrowIcon} />
          ) : (
            <img src={arrowIcon} style={{ transform: 'rotate(180deg)' }} />
          )
        ) : null}
      </G.Sortable>
    );
  };

  async function convertData(event, data) {
    if (!data) {
      toast('File is invalid', 'error');
      return;
    }

    const lines = data.split(/\r?\n/);
    const filtered = lines.filter(el => el.trim() !== '');
    const trimmedLines = filtered.map(line => line.replace(/,\s*$/, '')); // Trim trailing comma
    
    try {
      const result = await dispatch(getSurveyInstances(trimmedLines));
      if (result.status === 200) {
        const validFloIDList = result.data.filter((value) => (value?.floid)).map((value) => (value?.floid));
        const invalidFloIDList = trimmedLines.filter((id) => (!validFloIDList.includes(id)));
        
        if (invalidFloIDList.length > 0) {
          setInvalidTargetOrgs(invalidFloIDList);
          setConfirmModal({ show: true });
        }

        if (validFloIDList.length > 0) {
          setTargetOrgs(validFloIDList as string[]);
          setnewSurveyUpload(result.data);
          setSurveys(true);
          setPublish(false);
          setFileName(event.target.files?.[0]?.name);
        } else {
          event.target.value = '';
          setFileName('');
        }
      }
    } catch (error: any) {
      handleDelete();
      toast(error.data.description, 'error');
    }
  }

  const renderSurvey = () => {
    let data = newSurveyUpload;
    let surveys: any = data;
    // add Object.keys in case 'surveys' is empty [] or {}
    if (!surveys || !Object.keys(surveys).length) return null;
    const userComponents: ReactElement[] = [];

    surveys.forEach(
      ({ id, name, orgType, orgName, floid, countryId }, index) => {
        userComponents.push(
          <G.TableRow style={{ height: '3em' }} key={index}>
            <td style={{ color: '#000', cursor: 'pointer' }}>{floid}</td>
            <td style={{ color: '#000', cursor: 'pointer' }}>{orgName}</td>
            <td
              style={{
                color: '#000',
                cursor: 'pointer',
              }}
            >
              {countries.find((item) => item?.id === countryId)?.name || ''}
            </td>
            <td style={{ color: '#000', cursor: 'pointer' }}>{orgType}</td>
          </G.TableRow>
        );
      }
    );
    return userComponents;
  };

  const getFieldErrors = (errors) => {
    const fieldErrors = ableToSubmit(errors).filter(
      (x) => x.slug === 'details'
    );
    let missingValues: any = {};
    if (fieldErrors?.length > 0) {
      fieldErrors.forEach(({ field }) => {
        missingValues = { ...missingValues, [field]: field };
      });
    }
    return missingValues;
  };

  const save = async (isBack?: boolean): Promise<any> => {
    if (!state.description || !state?.frequency) {
      const missingValues = getFieldErrors(state);
      setErrors(missingValues);
      if (!state.description) { toast('Description Compulsory', 'error'); }
      return;
    }

    if (!state.startDate) {
      toast('startDate', 'error');
      return;
    }

    if (!state.endDate) {
      toast('endDate', 'error');
      return;
    }

    const arrayObj = {
      state: state,
      targetOrgs: targetOrgs,
      date: todayDate,
      surveyId: id,
    };

    return arrayObj;
  };

  const saveDraft = async (isBack?: boolean): Promise<any> => {
    if (!state.description) {
      const missingValues = getFieldErrors(state);
      setErrors(missingValues);
      if (!state.description) { toast('Description Compulsory', 'error'); }
      return;
    }

    const updateState = {};
    for (const key in state) {
      if (state.hasOwnProperty(key) && state[key] !== '') {
        updateState[key] = state[key];
      }
    }
    const arrayObj = {
      state: updateState,
      targetOrgs: targetOrgs,
      date: todayDate,
      surveyId: id,
    };

    return arrayObj;
  };

  const handleBack = (to: string) => {
    if (isDirty) {
      setShowModal(true);
      setLinkTo(to);
    } else {
      history.push(to);
    }
  };

  const showForm = useMemo(() => ((state.description && newSurveyUpload && hasSurveys) || (state.description && newSurveyUpload?.length === 0 && !hasSurveys) || !isEditSurvey), [state.description, newSurveyUpload, hasSurveys, isEditSurvey]);

  return (
    <>
      <S.HeadContainer>
        <S.Header
          onClick={() => handleBack('/latestSurveys/')}
        >
          <S.svg>
            <img src={backarrow} />{' '}
          </S.svg>
          <FormattedMessage id={messages?.AddInstance} />
        </S.Header>
      </S.HeadContainer>
      <S.space></S.space>
      <S.Content>
        <Row>
          <S.Title>
            <FormattedMessage id={messages?.Surveynameofadmin} />
          </S.Title>

          {errorStatus && (
            <b
              style={{
                float: 'right',
                position: 'absolute',
                right: 0,
              }}
            >
              <AsyncButton
                isSave
                isCancel
                disabled={false}
                style={{ color: '#AF3827' }}
                testId='next-button'
                messageId={'1 field needs attention'}
                onClick={handleDelete}
              />
            </b>
          )}
        </Row>

        {
          (
            showForm &&
            <>
              <S.Row>
                <S.Column>
                  <TextInput
                    testId='description'
                    isLight
                    error={errors.description && isEmpty(state.description)}
                    value={state.description || ''}
                    onChange={(value) => set('description', value)}
                    type='text'
                    label={messages.InstanceDescription}
                    placeholder={messages.Maxchars}
                    maxLen={200}
                    disabled={isEditSurvey && state.status > SURVEY_STATUS.Scheduled}
                    inputFieldStyles={{
                      backgroundColor:
                        isEditSurvey && state?.status > SURVEY_STATUS.Scheduled
                          ? '#E4E2E4'
                          : '#FFF',
                    }}
                  />
                </S.Column>
              </S.Row>

              <S.Row>
                <S.Column>
                  <S.Dates>
                    <S.Date>
                      <TextInput
                        Id='investmentStartDate'
                        isLight
                        error={errors.startDate}
                        value={state.startDate}
                        defaultValue={fdate2(state.startDate || '')}
                        onChange={(value) => {
                          set('startDate', value);
                          setState((state) => { // set end date to max 7 days after start date
                            const maxStartDate = addDate(value, 6);
                            const startMaxDate: number = new Date(maxStartDate).getTime();
                            const currentEndDate: number = new Date(state?.endDate).getTime();
                            return ({ ...state, endDate: ((currentEndDate <= startMaxDate) ? '' : state?.endDate ?? '') });
                          });
                        }}
                        type='date'
                        label={messages.OpenOn}
                        onBlur={handleInvestmentDates}
                        min={fdate2(todayDate || '')}
                        // max={addDate(state.endDate, -7)}
                        disabled={
                          isEditSurvey && state.status > SURVEY_STATUS.Scheduled
                        }
                        inputFieldStyles={{
                          backgroundColor:
                            isEditSurvey && state?.status > SURVEY_STATUS.Scheduled
                              ? '#E4E2E4'
                              : '#FFF',
                        }}
                      />
                    </S.Date>
                    <S.Date>
                      <TextInput
                        Id='investmentEndDate'
                        isLight
                        error={errors.endDate}
                        value={state.endDate}
                        defaultValue={fdate2(state.endDate || '')}
                        onChange={(value) => set('endDate', value)}
                        onBlur={handleInvestmentDates}
                        type='date'
                        label={messages.CloseOn}
                        // “Closed on” date can’t be less than 1 week after open date
                        min={((state?.startDate) ? addDate(state.startDate, 7) : fdate2(todayDate || ''))}
                        // “Closed on” date can’t be later than 1 year plus one day from “Open on” date
                        max={addDate(state.startDate, 366)}
                        // if status is closed, this field is disabled
                        disabled={isEditSurvey && state?.status > SURVEY_STATUS.Open}
                        inputFieldStyles={{
                          backgroundColor:
                            isEditSurvey && state?.status > SURVEY_STATUS.Open
                              ? '#E4E2E4'
                              : '#FFF',
                        }}
                      />
                    </S.Date>
                  </S.Dates>
                  <S.Description>{messages.surveyNotes}</S.Description>
                </S.Column>
              </S.Row>

              <S.Row>
                <S.Column>
                  <SurveyFrequency
                    status={state?.status ?? 0}
                    // error={errors?.frequency && !state?.frequency}
                    frequency={state?.frequency ?? 0}
                    setFrequency={(frequency: number) => {set('frequency', frequency);}} />
                </S.Column>
              </S.Row>

              <S.Row>
                <S.Column2>
                  <S.Label>{messages.TargetRespondents}</S.Label>
                  <S.Row>
                    <S.Description>{messages.TargetNotes}</S.Description>
                    {isShowingDeleteListButton && (
                      <b style={{
                        float: 'right',
                        position: 'absolute',
                        right: 0,
                      }}>
                        <AsyncButton
                          isSave
                          isCancel
                          disabled={false}
                          style={{ color: '#AF3827' }}
                          testId='next-button'
                          messageId={'DeleteList'}
                          onClick={handleDelete}
                        />
                      </b>
                    )}
                  </S.Row>
                </S.Column2>
              </S.Row>

              {/* Table */}
              <G.Table style={{ margin: '1em -1em 0em -1em' }}>
                <table>
                  <thead>
                    <tr>
                      {sortable('floid', 'FLO-ID', '15em')}
                      {sortable('name', 'OrganisationalName', '40em')}
                      {sortable('country', 'country', '')}
                      {sortable('orgType', 'orgType', '')}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {hasSurveys ? (
                      renderSurvey()
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            padding: '2em 1em 1em 1em',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }}
                        >
                          <b
                            style={{
                              fontStyle: 'italic',
                            }}
                          >
                            <FormattedMessage id='uploadTargetFloidlist' />
                          </b>
                          <S.List>{formatMessage({ id: 'serveyInstanceListRule' })?.split('||')?.map((text, index) => (<S.ListText key={index}>{text}</S.ListText>))}</S.List>
                          <div>
                            <S.ChooseFile htmlFor='chooseFile'>
                              <FormattedMessage id={'chooseFile'} />
                            </S.ChooseFile>
                            {
                              (fileName) &&
                              <label
                                style={{
                                  fontStyle: 'normal',
                                  width: 'fit-content',
                                  fontWeight: 'normal',
                                }}
                              >
                                {fileName ? fileName : messages.NoFileChosen}
                              </label>
                            }
                            <input
                              style={{ display: 'none' }}
                              type='file'
                              id='chooseFile'
                              name='survey definition'
                              accept='.csv'
                              onChange={(e) => {
                                const file = e.target.files;
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {}, false);

                                if (file) {
                                  reader.readAsText(file[0]);
                                }

                                reader.addEventListener('loadend', () => {
                                  convertData(e, reader.result);
                                });
                                isJson(newSurveyUpload);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </G.Table>
              {/* end of table */}
            </>
          ) ||
          (
            <S.Loading>
              <FormattedMessage id='loading' />...
            </S.Loading>
          )
        }
      </S.Content>

      {
        showForm &&
        <ActionButtons
          id={1}
          activeStep={0}
          publish={isPublishDisabled}
          errors={errors}
          onSave={save}
          saveDraft={saveDraft}
          setErrors={setErrors}
          status={state.status}
          isDraft={state?.isDraft}
          name={state.description}
          isLoading={isLoading_TargetRespondent}
          hasSurveys={hasSurveys}
        />
      }

      <Modal // Confirm Modal
        show={confirmModal.show}
        handleClose={() => {}}
        title={<FormattedMessage id='surveyInstanceInvalidFLOIDs' />}
        body={
          <S.ModalBody>
            <S.ModalBodyText>
              <ul>
                {invalidTargetOrgs.map((id) => (<li key={`invalid-floid-${id}`}>{id}</li>))}
              </ul>
            </S.ModalBodyText>
            <S.ModalBodyForm>
              <S.FormActionWrapper>
                <Button className='form-button save-button' variant='contained' onClick={() => { setConfirmModal({ show: false }); }}>
                  <FormattedMessage id='ok' />
                </Button>
              </S.FormActionWrapper>
            </S.ModalBodyForm>
          </S.ModalBody>
        }
        bodyStyle={{ left: 0 }}
        style={{ width: '640px' }}
      />
    </>
  );
};

export default AddSurveyInstance;
