import { createSlice } from '@reduxjs/toolkit';
import type {
  ApiOrganizationSimpleOut,
  ApiPOGeoLink,
  GenericHttpResult,
} from '@agunity/api-v4';

import { apiService } from 'api/apiService';
import type { RootState } from 'app/store';

export const hot = '../features/OrganizationDetails/redux.ts';

type UploadFile = {
  file: File | null;
  itemType: number;
  itemId: string;
  name?: string;
  description?: string;
  url?: string;
};

export type UpdateOrgConsentState = {
  itemType: number;
  itemId: number;
  consentId: number;
  state: number;
};

const initialState = {
  consentItems: [],
  orgFloIds: [],
  codImpacts: [],
  geoLinksList: null,
};

export const counterSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    setConsentItems: (state, action) => {
      state.consentItems = action.payload;
    },
    setOrgFloIds: (state, action) => {
      state.orgFloIds = action.payload;
    },
    setCODImpacts: (state, action) => {
      state.codImpacts = action.payload;
    },
    geoLinksList: (state, action) => {
      state.geoLinksList = action.payload;
    },
  },
});

export const {
  resetOrganization,
  setConsentItems,
  setOrgFloIds,
  setCODImpacts,
  geoLinksList,
} = counterSlice.actions;

export const selectOrgFloIds = (
  state: RootState
): ApiOrganizationSimpleOut[] => {
  return state.organization.orgFloIds;
};

export const getGeoLinksList = (state: RootState): any[] | null =>
  state.organization.geoLinksList;

export const getOrgFLOIDs = (orgId: number, force: boolean = true) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]> | void> => {
    if (!orgId) return;
    const orgFloIds = selectOrgFloIds(getState());
    if (!force && orgFloIds.length) return;
    const response = await apiService.fairtradeChildOrgsV11UserManagementDetail(
      orgId
    );
    if (response.status === 200) dispatch(setOrgFloIds(response.data));
    return response;
  };
};

export const uploadFile = ({ file, ...params }: UploadFile) => {
  return async (): Promise<GenericHttpResult<number> | void> => {
    if (!file) return;
    const data: any = new FormData();
    const fileBlob = new Blob([file.slice()], { type: file.type });
    data?.append('document', fileBlob, file.name ?? params?.name);
    data?.append('documentDetails', JSON.stringify(params));
    const response = await apiService.documentV12UploadCreate(data);
    return response;
  };
};

export const deleteFile = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.documentV10DeleteDelete(documentKey);
    return response;
  };
};

export const downloadDocument = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService.documentV10DownloadDetail(documentKey);
    return response;
  };
};

export const fetchGeoLinks = () => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<ApiPOGeoLink[]>> => {
  const response = await apiService.fairtradeV10GeoLinksList();
  if (response.status === 200) {
    dispatch(geoLinksList(response.data));
  } else {
    dispatch(geoLinksList([]));
  }
  return response;
});

export const updateGeoLink = ({ method, data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const api = { POST: apiService.itemDataV10ItemCreate, PATCH: apiService.itemDataV11ItemPartialUpdate };
  const { id, geoLink } = data;
  const body = { itemType: 1, /* Item Type 1 = Organization */ key: 'GeoLink', /* Item Type */ itemId: id, /* Organization ID */ value: geoLink /* GeoLink */ };
  const response = await api[method](body);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const uploadGeoLinks = ({ data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const response = await apiService.fairtradeV10GeoLinksCreate(data);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const downloadGeoLinksDocument = ({ key }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<Blob>> => {
  const response = await apiService.documentV10DownloadDetail(key);
  return response;
});

export default counterSlice.reducer;
