import styled from 'styled-components';

export const Outer = styled.div<any>`
  width: 100%;
  height: 100%;
  padding: ${(props) => (!props.$notPadded ? '1em' : '0px')};
  position: relative;
  padding-left: ${(props) =>
    props.$fullWidth
      ? '1em'
      : !props.$notPadded && !props.$fullWidth
        ? '17em'
        : '17em'};
  padding-top: ${() => (process.env.REACT_APP_DEMO === '1' ? '6.75em' : '4em')};
  background-color: #f6f7f7;
`;

export const Container = styled.div<any>`
  width: 100%;
  min-height: 35em;
  background-color: ${(props) => (props.$noBG ? '#F6F7F7' : 'white')};
  padding: 0;
  border: none;
  position: relative;
`;
