import { FormattedMessage } from 'react-intl';
import { useContext, useEffect, useState, ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { DirtyContext } from 'components/Dirty/Context';
import * as S from './ActionButtons.styled';
import AsyncButton from 'components/AsyncButton';
import deleteIcon from 'assets/icons/deleteicon.svg';
import closeIcon from 'assets/icons/closeRed.svg';
import archiveIcon from 'assets/icons/archive.svg';
import Modal from '../../../components/Modal';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  addSurveyInstance as setAddSurveyInstance, archiveSurveyOrInstances,
  deleteSurveyInstances,
  saveSurveyInstance,
  saveSurveyInstanceDraft,
} from '../redux';
import { ErrorsContext } from 'components/Errors/Context';
import { useToast } from 'features/SPOReport/hooks';
import { fdate2 } from 'utils';
import { ArchiveActiveStatus_Inactive, FILE_TYPES, SURVEY_STATUS } from '../../../appConstants';

type Save = (isBack?: boolean) => Promise<any>;
type Props = {
  id: number;
  activeStep: number;
  onSave?: Save;
  saveDraft?: Save;
  errors?: boolean;
  publish?: boolean;
  setErrors?: Function;
  status?: number;
  isDraft?: boolean;
  name?: string;
  isLoading?: boolean;
  hasSurveys?: boolean;
};

function ActionButtons({
  id,
  activeStep,
  publish,
  onSave,
  saveDraft,
  errors,
  status = 0,
  isDraft,
  name,
  isLoading,
  hasSurveys,
  setErrors: setStateErrors,
}: Props) {
  const isNewInstance = useRouteMatch('/latestSurveys/:id');
  const { setIntersected, intersected } = useContext(DirtyContext);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setTimeout(
      () => setIntersected({ ...intersected, actionBar: !!inView }),
      1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, setIntersected]);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const history = useHistory();
  const isEditSurvey =
    history.location.pathname.startsWith('/latestSurveysEdit');
  const isSurveyScheduleAndHasNoFloId = !hasSurveys && status === SURVEY_STATUS.Scheduled; // under scheduled status, if survey has no target floid
  const dispatch = useDispatch();
  const { setErrors } = useContext(ErrorsContext);
  const { toast } = useToast();
  const { setDirty } = useContext(DirtyContext);

  const handlePublish = async () => {
    setShowConfirm(true);
  };

  const handleSave = async () => {
    try {
      const ok = await save();
      if (!ok) return true;
      dispatch(setAddSurveyInstance(null));
      toast('saveInstanceSucceed', 'success');
      history.push('/latestSurveys/');
    } catch (e) {
      toast('errors', 'error');
    }
  };

  const handleDelete = async () => {
    setShowDelete(true);
  };

  const publishMe = async () => {
    try {
      const ok = await publishNow();
      if (!ok) return true;
      dispatch(setAddSurveyInstance(null));
      toast('publishInstanceSucceed', 'success');
      history.push('/latestSurveys/');
    } catch (e) {
      toast('errors', 'error');
    }
  };

  async function publishNow() {
    try {
      const r: any = onSave ? await onSave() : null;
      if (!r) {
        setShowConfirm(false);
        return false;
      }
  
      let body = {
        id: isEditSurvey ? Number(r.surveyId.surveyId) : 0,
        updatedAsOf: fdate2(Date.now()),
        updatedBy: 0,
        createdBy: 0,
        createdAsOf: fdate2(Date.now()),
        surveyId: Number(r.surveyId.id),
        name: r.state.description,
        startDate: r.state.startDate,
        endDate: r.state.endDate,
        targetOrgs: r.targetOrgs,
        reminderFrequency: r.state.frequency || 0
      };

      const result: any = isEditSurvey
        ? await dispatch(saveSurveyInstanceDraft(body))
        : await dispatch(saveSurveyInstance(body));
      
      if (result.status === 200) { 
        setDirty(false);
        return true;
      } else if (result.errors && result.errors.length) {
        toast('Error', 'error', {
          description: result.errors[0],
        });
        if (setStateErrors) {
          setStateErrors(result.errors.join(', '));
        }
        return false;
      }
    } catch (error: any) {
      const errorMessage = error?.data?.description || 'An unexpected error occurred.';
      console.error('Failed to save survey:', errorMessage);
  
      toast(errorMessage, 'error');
  
      if (setStateErrors) {
        setStateErrors(errorMessage);
      }
  
      return false;
    }
  }

  async function save() {
    try {
      // todo below the 'saveDraft' is a bit strange, replace with obj or primitive value from 'addSurveyInstance', need further scrutiny
      const r: any = saveDraft ? await saveDraft() : null;
      if (!r) return false;
      let body = {
        id: isEditSurvey ? Number(r.surveyId.surveyId) : 0,
        updatedAsOf: fdate2(Date.now()),
        updatedBy: 0,
        createdBy: 0,
        createdAsOf: fdate2(Date.now()),
        surveyId: Number(r.surveyId.id),
        name: r.state.description,
        startDate: r.state.startDate,
        endDate: r.state.endDate,
        targetOrgs: r.targetOrgs,
        isDraft: status === SURVEY_STATUS.Draft, // only be true if it's draft
        reminderFrequency: r.state.frequency || 0
      };

      const result: any = isEditSurvey
        ? await dispatch(saveSurveyInstanceDraft(body))
        : await dispatch(saveSurveyInstance(body));
  
      if(result.status === 200) {
        setDirty(false);
        return true;
      } else if (result.errors && result.errors.length) {
        toast('errors', 'error', {
          errors: result.errors[0],
        });
        setStateErrors && setStateErrors(result.errors.join(','));
        return false;
      }
    } catch(error: any) {
      const errorMessage = error?.data?.description || 'An unexpected error occurred.';
      
      console.error('Failed to save survey:', errorMessage);

      toast(errorMessage, 'error');

      if (setStateErrors) {
        setStateErrors(errorMessage);
      }

      return false;
    }
    return true;
  }

  const renderConfirmationToastMessage = (status: number = 0) => {
    switch (status) {
      case SURVEY_STATUS.Closed:
        return 'InstanceArchiveSucceed';
      case SURVEY_STATUS.Open:
        return 'InstanceCloseSucceed';
      default:
        return 'InstanceDeleteSucceed';
    }
  };

  const handleConfirmDelete = async () => {
    const r: any = saveDraft ? await saveDraft() : null;
    if (r.surveyId.surveyId) {
      try {
        let response;
        if (status <= SURVEY_STATUS.Scheduled) {
          // delete instance if status is draft or scheduled
          response = await dispatch(deleteSurveyInstances(r.surveyId.surveyId));
        } else if (status === SURVEY_STATUS.Open) {
          // close instance if status is open
          response = await dispatch(
            saveSurveyInstanceDraft({
              id: Number(r.surveyId.surveyId),
              updatedAsOf: fdate2(Date.now()),
              updatedBy: 0,
              createdBy: 0,
              createdAsOf: fdate2(Date.now()),
              surveyId: Number(r.surveyId.id),
              name: r.state.description,
              startDate: r.state.startDate,
              endDate: fdate2(new Date(new Date().toUTCString()).setDate(new Date(new Date().toUTCString()).getDate() - 2)),
              targetOrgs: r.targetOrgs,
              isDraft: false,
              reminderFrequency: r.state.frequency || 0
            })
          );
        } else {
          // archive instance if status is closed
          response = await dispatch(archiveSurveyOrInstances(FILE_TYPES.ExtSurveyInstance, Number(r.surveyId.surveyId),ArchiveActiveStatus_Inactive));
        }
        // @ts-ignore
        if (response?.status === 'error') {
          toast('errors', 'error');
          return;
        }
        toast(renderConfirmationToastMessage(status), 'success');
        history.push('/latestSurveys/');
      } catch (e) {
        console.error(e);
        toast('errors', 'error');
      } finally {
        setShowDelete(false);
      }
    }
  };

  const footer: ReactElement = (
    <div className='row'>
      <div className='col'>
        <AsyncButton
          onClick={setShowConfirm}
          noPadding
          isCancel
          messageId={isEditSurvey ? 'cancel' : 'Discard'}
        />
      </div>
      <div className='col'>
        <AsyncButton
          messageId={isEditSurvey ? 'confirm' : 'Publish'}
          onClick={publishMe}
          isBlue
          testId='submit'
        />
      </div>
    </div>
  );

  const footer2: ReactElement = (
    <div className='row'>
      <div className='col'>
        <AsyncButton
          onClick={setShowDelete}
          noPadding
          isCancel
          messageId='cancel'
        />
      </div>
      <div className='col'>
        <AsyncButton
          messageId='confirm'
          disabled={false}
          onClick={handleConfirmDelete}
          isBlue
          testId='submit'
        />
      </div>
    </div>
  );

  const deleteButtonText = (status: number = 0) => {
    switch (status) {
      case SURVEY_STATUS.Closed:
        return 'ArchiveInstances';
      case SURVEY_STATUS.Open:
        return 'CloseInstances';
      default:
        return 'DeleteInstances';
    }
  };

  const deleteModalTitle = (status: number = 0) => {
    switch (status) {
      case SURVEY_STATUS.Closed:
        return <FormattedMessage id='InstanceArchiveConfirm'>{txt => <S.ArchiveTitle>{txt}</S.ArchiveTitle>}</FormattedMessage>;
      case SURVEY_STATUS.Open:
        return <FormattedMessage id='InstanceCloseConfirm' />;
      default:
        return <FormattedMessage id='InstanceDeletionConfirm' />;
    }
  };

  const deleteModalBody = (status: number = 0, name: string = '') => {
    switch (status) {
      case SURVEY_STATUS.Closed:
        return (
          <div>
            <FormattedMessage id='InstanceArchiveConfirmText' />
            <p
              style={{
                height: '50px',
                border: '1px solid #E4E2E4',
                padding: '0 24px',
                lineHeight: '50px',
                marginTop: '24px',
              }}
            >
              {name}
            </p>
          </div>
        );
      case SURVEY_STATUS.Open:
        return (
          <div>
            <FormattedMessage id='InstanceCloseConfirmText' />
            <p
              style={{
                height: '50px',
                border: '1px solid #E4E2E4',
                padding: '0 24px',
                lineHeight: '50px',
                marginTop: '24px',
              }}
            >
              {name}
            </p>
          </div>
        );
      default:
        return (
          <div>
            <FormattedMessage id='InstanceDeletionConfirmText' />
            <p
              style={{
                height: '50px',
                border: '1px solid #E4E2E4',
                padding: '0 24px',
                lineHeight: '50px',
                marginTop: '24px',
              }}
            >
              {name}
            </p>
          </div>
        );
    }
  };

  const renderIcon = (status: number = 0) => {
    switch (status) {
      case SURVEY_STATUS.Closed:
        return archiveIcon;
      case SURVEY_STATUS.Open:
        return closeIcon;
      default:
        return deleteIcon;
    }
  };

  return (
    <S.Container ref={ref}>
      <Modal
        show={showConfirm}
        handleClose={setShowConfirm}
        title={<FormattedMessage id='publishTitle' />}
        body={<FormattedMessage id='publishMessage' />}
        footer={footer}
        style={{ padding: '26px 48px' }}
      />

      <Modal
        show={showDelete}
        handleClose={setShowDelete}
        title={deleteModalTitle(status)}
        body={deleteModalBody(status, name)}
        footer={footer2}
        style={{ padding: '26px 48px' }}
      />

      {
        !isNewInstance ? (
          <div
            onClick={handleDelete}
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginTop: '12px',
            }}
          >
            <img
              style={{ width: '14px', position: 'absolute', left: '4px' }}
              src={renderIcon(status)}
              alt={''}
            />
            <AsyncButton
              isSave
              isCancel
              disabled={isLoading}
              style={{ color: '#AF3827' }}
              testId='next-red-button'
              messageId={deleteButtonText(status)}
            />
          </div>
        ) : <div></div>
      }

      <S.RightButtons>
        <AsyncButton
          testId='next-button'
          isBlue
          disabled={publish || isLoading}
          messageId={'publish'}
          onClick={handlePublish}
        />
        <AsyncButton
          testId='save-button'
          isSave
          disabled={(status >= SURVEY_STATUS.Closed) || isLoading || isSurveyScheduleAndHasNoFloId}
          isBlue={activeStep === 4}
          messageId={isDraft ? 'saveDraft' : 'saveInstance'}
          onClick={handleSave}
        />
      </S.RightButtons>
    </S.Container>
  );
}

export default ActionButtons;
