import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { PrintContext } from 'components/Printing/Context';

export function useWithPrint(): { isPrinting: boolean } {
  const location = useLocation();
  const { setPrinting, isPrinting } = useContext(PrintContext);

  useEffect(() => {
    const isPrinter = location.pathname.includes('print');
    setPrinting(isPrinter);
  }, [location, setPrinting]);

  return { isPrinting };
}
