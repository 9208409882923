import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { updateCssVars } from 'components/Theme/Provider';
import * as S from './Container.styled';
import theme from 'components/Theme/themes/theme.json';

const notPaddedList = ['/'];
export const noBGList = [
  '/',
  /(\/profile)/,
  /(\/reports\/[0-9])/,
  /(\/reports\/new)/,
  /(\/report\/draft\/[0-9])/
];

function Container({ children,showFullWidth }) {
  const location = useLocation();
  const noBG = !!noBGList.find(item => typeof item === 'string' ? item === location.pathname : item.test(location.pathname));
  const notPadded = !!notPaddedList.find(item => location.pathname === item);

  useEffect(() => {
    updateCssVars(noBG ? theme.AltBG : theme.BG);
  }, [noBG]);

  return (
    <S.Outer $notPadded={notPadded} $fullWidth={ showFullWidth }>
      <S.Container $noBG={noBG}>
        {children}
      </S.Container>
    </S.Outer>
  );
}

export default Container;
