import ConsentModal from '../../features/HomePage/components/ConsentDataModal';
import { useEffect, useState } from 'react';
import { fetchProfile, selectProfile } from '../../features/login/redux';
import {
  fetchOrganisation,
  selectOrganisation,
} from '../../features/SPOReport/redux';
import { useDispatch } from 'react-redux';
import { ConsentStateItem } from '../../types';
import { useAppSelector } from '../../app/hooks';
import { ConsentState } from '../../appConstants';

// this component is refactored from Homepage
const Index = () => {
  const dispatch = useDispatch();

  const userConsents = useAppSelector(selectProfile)?.consents;
  const orgConsents = useAppSelector(selectOrganisation)?.consents;
  const [consentContent, setConsentContent] = useState<ConsentStateItem[]>([]);
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [consentLength, setConsentLength] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const handleClose = () => {
    setShowConsentModal(false);
  };
  const handleAccept = () => {
    if (currentIndex + 1 === consentContent.length) {
      setCurrentIndex(-1);
      setShowConsentModal(false);
      dispatch(fetchProfile());
      dispatch(fetchOrganisation());
    } else {
      setCurrentIndex(prev => prev + 1);
      setShowConsentModal(true);
    }
  };
  const isMoreThan6Month = (dateString: string) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    givenDate.setMonth(givenDate.getMonth() + 6);

    return givenDate < currentDate;
  };
  const handleDecline = () => {
    setShowConsentModal(false);
  };
  const orgsString = JSON.stringify(orgConsents);
  const userString = JSON.stringify(userConsents);
  useEffect(() => {
    if (Array.isArray(userConsents) || Array.isArray(orgConsents)) {
      const filterOrgConsents = ({ givenConsent, updatedAsOf }) => (
        givenConsent === ConsentState.Blank || (givenConsent === ConsentState.Rejected && isMoreThan6Month(updatedAsOf))
      );
      const filteredUserConsents = (userConsents || []).filter(({ givenConsent }) => givenConsent === ConsentState.Blank).map(item => ({ ...item, testType: 'user' }));
      const filteredOrgConsents = (orgConsents || []).filter(filterOrgConsents).map(item => ({ ...item, testType: 'org' }));;
      const notGivenConsent = [ ...filteredOrgConsents, ...filteredUserConsents];
      setConsentContent(notGivenConsent);
      if (notGivenConsent?.length) {
        setConsentLength(notGivenConsent?.length);
        setShowConsentModal(true);
        setCurrentIndex(0);
      } else {
        setShowConsentModal(false);
        setCurrentIndex(-1);
      }
    } else {
      setShowConsentModal(false);
      setCurrentIndex(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgsString, userString]);

  return currentIndex >= 0 ? (
    <ConsentModal
      consentLength={consentLength}
      id={'consentModal-' + currentIndex}
      keepMounted
      open={showConsentModal}
      onClose={handleClose}
      value={consentContent[currentIndex]}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      handleAccept={handleAccept}
      handleDecline={handleDecline}
    />
  ) : null;
};

export default Index;
