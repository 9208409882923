import MuiDropdown from '../MuiDropdown/MuiDropdown';
import { useState, useContext, useEffect } from 'react';
import { LanguageContext } from 'components/Language/Context';
import { useDispatch } from 'react-redux';
import {
  currencyConversions,
  getCurrencyConversions,
  selectedCurrency as setSelectedCurrency,
  selectSelectedCurrency
} from 'features/SPOReport/redux';
import { useAppSelector } from 'app/hooks';

export enum CurrencyTypes {
  Original = 'Original currency',
  USD = 'USD',
  EUR = 'EUR'
}

const CurrencyDropdown = ({
  setSelectedCurrencyx
}: {
  setSelectedCurrencyx: Function;
}) => {
  const { messages } = useContext(LanguageContext);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const [selectedCur, setSelectedCur] = useState<string>(
    selectedCurrency === CurrencyTypes['Original']
      ? messages.originalCur
      : selectedCurrency
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedCurrencyx(selectedCur);
  }, [selectedCur, setSelectedCurrencyx]);

  const options = ['Original', 'EUR', 'USD'].map((val, index) => {
    return {
      id: index,
      value: val,
      label: val === 'Original' ? messages.originalCur : undefined
    };
  });
  return (
    <MuiDropdown
      style={{
        minWidth: '160px',
        marginRight: '13px',
        textAlign: 'left'
      }}
      labelColor='black'
      labelWeight={700}
      value={selectedCur}
      onChange={async (event) => {
        setSelectedCur(
          (event?.target.value as string) === 'Original'
            ? messages.originalCur
            : (event?.target.value as string)
        );
        dispatch(
          setSelectedCurrency(CurrencyTypes[event?.target.value as string])
        );
        dispatch(currencyConversions(undefined));
        const response: any = await dispatch(getCurrencyConversions());
        if (response.status === 200)
          dispatch(currencyConversions(response.data));
      }}
      options={options}
    />
  );
};

export default CurrencyDropdown;
