import LanguageProvider from 'components/Language/Provider';
import ThemeProvider from 'components/Theme/Provider';
import PrintProvider from 'components/Printing/Provider';
import DirtyProvider from 'components/Dirty/Provider';

function AppProviders({ children }) {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <PrintProvider>
          <DirtyProvider>
            {children}
          </DirtyProvider>
        </PrintProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default AppProviders;
