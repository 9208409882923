import { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { LanguageContext } from './Context';

export function useLocaleUpdate(funcs: Function[] | Function) {
  const [responses, setResponses] = useState<any[]>();
  const [errors, setErrors] = useState<any[]>();
  const { locale } = useContext(LanguageContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      try {
        if (Array.isArray(funcs)) {
          const ps = funcs.map(f => dispatch(f(locale)));
          const rs = await Promise.all(ps);
          setResponses(rs);
        } else {
          const r = await dispatch(funcs(locale));
          if (r) setResponses([r]);
          if (r && r.errors) setErrors(r.errors);
        }
      } catch(e) {}
    };
    f();
  }, [dispatch, funcs, locale]);

  return { responses, errors };
};
