import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import type { RootState } from 'app/store';
import type { ThunkArgs, IResponse, Role, Country, Users, OrgType } from 'types';
import { selectOrganisation } from 'features/SPOReport/redux';
import { apiService } from 'api/apiService';
import type { 
  ApiCreatePersonRequest,
  ApiFTSimpleIdentity,
  ApiFullRole,
  ApiIdentity,
  ApiOrganization,
  ApiProfileCreationOut,
  ApiRealSimpleIdentity,
  ApiUpdatePersonRequest,
  GenericHttpResult,
  ApiItemGroupMember,
} from '@agunity/api-v4';

export const hot = '../features/Administration/redux.ts';

export interface UserMgtState {
  countries: Country[];
  roles: Role[];
  userList: Users[];
  activity: any[];
  orgType: OrgType;
  functionRole: any[];
  pn: any[];
  featureFlags: string[];
}

const initialState: UserMgtState = {
  countries: [],
  roles: [],
  userList: [],
  activity: [],
  orgType: {} as OrgType,
  functionRole: [],
  pn: [],
  featureFlags: []
};

export const counterSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    countries: (state, action) => {
      state.countries = action.payload;
    },
    roles: (state, action) => {
      state.roles = action.payload;
    },
    userList: (state, action) => {
      state.userList = action.payload;
    },
    activity: (state, action) => {
      state.activity = action.payload;
    },
    orgType: (state, action) => {
      state.orgType = action.payload;
    },
    functionRole: (state, action) => {
      state.functionRole = action.payload;
    },
    pn: (state, action) => {
      state.pn = action.payload;
    },
    featureFlags: (state, action) => {
      state.featureFlags = action.payload;
    }
  }
});

export const { countries, roles, userList, activity, orgType, functionRole, pn, resetUserState, featureFlags } = counterSlice.actions;

export const selectCountries = (state: RootState) => state.users.countries;

export const selectRoles = (state: RootState) => state.users.roles;

export const selectUserList = (state: RootState) => state.users.userList;

export const selectActivity = (state: RootState) => state.users.activity;

export const selectOrgType = (state: RootState) => state.users.orgType;

export const selectFunction= (state: RootState) => state.users.functionRole;

export const selectPN= (state: RootState) => state.users.pn;

export const selectFeatureFlags= (state: RootState) => state.users.featureFlags;

export const fetchRoles =
  () =>
    async (): Promise<GenericHttpResult<ApiFullRole[]>> => {
      const response = await apiService.roleV20ItemsFullList();
      return response;
    };

export const fetchRolesFull =
  (orgId?: number) =>
    async (
      dispatch: Function,
      getState: Function,
    ): Promise<GenericHttpResult<ApiFullRole[]>> => {
      const state = getState();
      const id = orgId || selectOrganisation(state).id;
      const response = await apiService.organizationV20SupportedRolesFullDetail(id);
      if (!orgId) dispatch(roles(uniqBy(response.data, 'id')));
      return response;
    };

export const fetchCountries =
  () =>
    async (dispatch: Function): Promise<void> => {
      try {
        const response = await apiService.systemV10CountriesList();
        dispatch(countries(uniqBy(response.data, 'id')));
      } catch (error) {
        console.error(error);
      }
    };

export const fetchUserList =
  () =>
    async ( dispatch: Function ): Promise<GenericHttpResult<ApiFTSimpleIdentity[]>> => {
      const response = await apiService.fairtradeV10ListOfUsersList();
      dispatch(userList(response.data));
      return response;
    };

export const createUpdateUser =
  (
    id: number,
    firstName: string,
    email: string,
    locale: string,
    roles: any[],
    address: ApiIdentity['address'],
    security: {},
    updatedBy: number,
    orgId: number,
    middleName?: string,
    familyName?: string,
    isActive?: boolean,
    updatedAsOf?: string,
    dateCreated?: string,
    dateOfBirth?: string,
    userId?: number
  ) =>
    async () => {
      const isPOST = id < 0;
      const today = new Date().toISOString();
      const defaultPayload = {
        identity: {
          firstName,
          middleName,
          familyName,
          email,
          address,
          gender: 'O',
          locale,
          updatedBy,
          dateOfBirth: dateOfBirth || today,
          hasPassword: true,
        },
        roles,
        orgId,
        supportOnboardingEmail: false,
      };
      
      if(isPOST){
        // Create User
        const payloadCreate: ApiCreatePersonRequest = { 
          ...defaultPayload, 
          identity: { 
            ...defaultPayload.identity, 
            isActive: true,
            createdAsOf: today,
            updatedAsOf: today
          }
        };
        const response = await apiService.accountV11ItemCreate(payloadCreate);
        return response as GenericHttpResult<ApiProfileCreationOut>;
      } else {
        // Update User
        const payloadUpdate: ApiUpdatePersonRequest = {
          ...defaultPayload,
          identity: {
            ...defaultPayload.identity,
            id,
            isActive,
            createdAsOf: dateCreated,
            updatedAsOf,
          },
          users: [{ id: userId, roles }]
        };
        const response = await apiService.accountV11ItemPartialUpdate(payloadUpdate);
        return response as GenericHttpResult<void>;
      }
    };

export const toggleUserActive =
  (
    id: number,
    isActive: boolean,
  ) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.accountV10ItemActiveFlagPartialUpdate(id, isActive);
      return response;
    };
    
export const managedOrgs =
  (data: { itemType: number; itemId: number }[], userId: number) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = apiService.fairtradeItemGroupManagedOrgsV10ListCreate2(userId, data);
      return response;
    };

export const getManagedOrgs =
  (userId: number) =>
    async (): Promise<GenericHttpResult<ApiItemGroupMember[]>> => {
      const response = await apiService.fairtradeItemGroupManagedOrgsV10ListDetail(userId);
      return response;
    };

export const fetchUserActivity =
  () =>
    async (dispatch: Function): Promise<void> => {
      try {
        const response = await apiService.planV10LastUsageList();
        await dispatch(activity(response.data));
      } catch (error) {
        console.error(error);
      };
    };

export const fetchOrgType =
  () =>
    async (dispatch: Function): Promise<void> => {
      const name = 'OrgType';
      try {
        const response = await apiService.enumV10ItemDetail(name);
        dispatch(orgType(response.data));
      } catch (error) {
        console.error(error);
      }
    };

export const fetchFunction =
  () =>
    async (dispatch: Function): Promise<void> => {
      try {
        const response = await apiService.functionV10AssignableList();
        dispatch(functionRole(response.data));
      } catch (error) {
        console.error(error);
      }
    };

export const fetchPN =
  () =>
    async (dispatch: Function): Promise<void> => {
      try {
        const response = await apiService.fairtradeV10ChildOrgsParentsList();
        dispatch(pn(response.data));
      } catch (error) {
        console.error(error);
      }
    };

export const createOrg =
  (org: ApiOrganization) =>
    async (): Promise<GenericHttpResult<number>> => {
      const response = await apiService.organizationV20ItemCreate(org);
      return response;
    };

export const editOrg =
(org: ApiOrganization) =>
  async (): Promise<GenericHttpResult<number>> => {
    if(!org.id) throw { data: { description: 'Missing org id' } };
    const response = await apiService.organizationV20ItemPartialUpdate(org.id, org);
    return response;
  };

export const changeOrgStatus =
  (orgId: number, isActive: boolean) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.organizationV10ItemActiveFlagPartialUpdate(orgId, isActive);
      return response;
    };

export const fetchPermissionsUsers =
  (permissionId: number, orgId: number) =>
    async (): Promise<GenericHttpResult<ApiRealSimpleIdentity>> => {
      const response = await apiService.organizationV11PermissionedUsersDetail(orgId, permissionId);
      return response;
    };
    
export const fetchFeatureFlags =
  () =>
    async (dispatch: Function): Promise<void> => {
      try {
        const response = await apiService.featureV10FlagsList();
        dispatch(featureFlags(response.data));
      } catch (error) {
        console.error(error);
      }
    };

export const init =
  () =>
    async (dispatch: Function, _: void): Promise<void> => {
      const data: string | null = sessionStorage.getItem('auth');
      if (data) {
        dispatch(fetchRoles());
        dispatch(fetchRolesFull());
        dispatch(fetchCountries());
        dispatch(fetchUserList());
        dispatch(fetchOrgType());
        dispatch(fetchFunction());
        dispatch(fetchPN());
        dispatch(fetchFeatureFlags());
      }
    };

export default counterSlice.reducer;
