import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 18em;
  height: 100vh;
  background-color: lightblue;
  position: relative;
  position: fixed;
  top: ${() => (process.env.REACT_APP_DEMO === '1' ? '2.75em' : '0')};
  left: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  float: left;
  font-size: 0.9em;
  padding-bottom: 3.75rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s transform;
  transition: 0.3s transform;
  list-style-type: none;
  padding: 0;
  background-color: #1f2b3d;
`;

export const Logo = styled.div`
  background-color: #1e2b3d;
  min-width: 10em;
  height: 5em;
  display: flex;
  justify-content: center;
`;

export const LogoImage = styled.img`
  width: 13em;
  height: 3em;
  margin: 1em;
`;
export const Unorderedlist = styled.ul`
  list-style-type: none;
  list-style: none;
  padding-left: 0;
  background-color: #1e2b3d;
  background-color: #1f2b3d;
  width: 100%;
  overflow: hidden auto;
`;

export const List = styled.li<any>``;

export const TopMenu = styled.div<any>`
  cursor: pointer;
  left: auto;
  right: 0;
  border-left: ${(props) =>
    props.$onPage || props.$subActive
      ? '4px solid #c7d13c'
      : '4px solid transparent'};
  background-color: ${(props) =>
    props.$onPage || props.$subActive ? '#c7d13c' : '#1F2B3D'};
  top: 12px;
  display: flex;
  padding: 1em 1em 1em 2em;
  font-size: 1em;
  width: 100%;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
`;

export const SubMenu = styled.div<any>`
  padding: 1em 2em;
  background-color: ${(props) => (props.$active ? '#2C3D52' : '#2C3D52')};
`;

export const SubMenuItem = styled.div<any>`
  padding: 1em 3em;
  position: relative;
  display: flex;
  cursor: pointer;
  color: ${(props) => (props.$onPage ? '#fff' : '#c4cede')};
  font-weight: ${(props) => (props.$onPage ? '700' : 'normal')};
  font-size: 0.9em;
  margin-right: 0;
  margin-right: -2.2em;
  &:hover {
    font-weight: 700;
    color: #ffffff;
  }
  span {
    font-size: 10px;
    line-height: 11.72px;
    color: ${(props) => (props.$onPage ? '#fff' : '#c4cede')};
    position: absolute;
    top: -1px;
  }
`;

export const link = styled.a<any>`
  display: block;
  padding: 17px 0;
  position: relative;
  display: block;
  padding: 15px 0 15px 25px;
  color: ${(props) => (props.$onPage ? '#c7d13c' : '#c4cede')};
  -webkit-transition: 0s;
  transition: 0s;
`;

export const span = styled.span<any>`
  margin-top: 5px;
  color: ${(props) =>
    props.$onPage || props.$subActive ? '#1F2B3D' : '#c4cede'};
  width: 140px;
  &:hover {
    ${(props) => (!props.$onPage && !props.$subActive ? 'color: white;' : '')}
  }
`;

export const Icon = styled.div<any>`
  height: 5px;
  width: 5px;
  background-color: ${(props) =>
    props.$onPage || props.$subActive ? '#c7d13c' : '#1F2B3D'};
  color: ${(props) => (props.$onPage || props.$subActive ? '#1F2B3D' : '#fff')};
  margin-right: 30px;
  position: relative;
  top: 0.15em;

  path {
    fill: ${(props) => (props.$onPage || props.$subActive ? '#1F2B3D' : '#fff')};
  }
`;

export const SVG = styled.img`
  width: fit - content;
  margin-right: 0.5em;
`;
