import { FormattedMessage } from 'react-intl';
import { toast as toastify, TypeOptions } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect, useState, useContext, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';

import { ErrorsContext } from 'components/Errors/Context';
import {
  PERMISSIONS,
  PLAN_STATUS,
  USER_CATEGORY,
  WRAPPER_STATUS
} from 'appConstants';
import {
  ChildOrganisation,
  IResponse,
  PlanWrapper,
  PlanWrapperSummary,
  Report
} from 'types';
import {
  selectReport,
  selectReports,
  report as setReport,
  selectChildOrganisation,
  fetchWrapperSummary,
  fetchWrapperSummary2
} from './redux';
import { useAppSelector } from 'app/hooks';
import { selectProfile } from 'features/login/redux';
import { detectUserCategory } from 'utils';
import details from './components/BeneficiaryEntry/Details';

export function useParamId(): { error: boolean; id: number } {
  const [error, setError] = useState<boolean>(false);
  const [id, setId] = useState<number>(-1);
  const report = useAppSelector(selectReport);
  const reportsData = useAppSelector(selectReports);
  const dispatch = useDispatch();
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const location = useLocation();
  const { setErrors } = useContext(ErrorsContext);

  useEffect(() => {
    if (params?.id === 'new' && report?.id !== -1) {
      setErrors([]);
      const newReport = { id: -1, status: PLAN_STATUS.Draft } as Report;
      sessionStorage.setItem('touched', '{}');
      dispatch(setReport(newReport));
      return;
    } else if (params?.id !== 'new') {
      const pId = parseInt(params?.id || '-1');
      setId(pId);
      if (
        pId >= 0 &&
        reportsData?.length > 0 &&
        (!report || report.id !== pId)
      ) {
        setErrors([]);
        const r = reportsData.find(({ id }) => id === pId);
        if (r) {
          dispatch(setReport(r));
          sessionStorage.setItem('touched', '{}');
        } else setError(true);
      }
    }
  }, [
    report,
    reportsData,
    params.id,
    dispatch,
    history,
    location.pathname,
    setErrors
  ]);

  return { error, id };
}

export function useToast(): {
  toast: (msg: string, type: TypeOptions, values?: any) => void;
  } {
  const [msgs, setMsgs] = useState<string[]>([]);

  const toast = (msg: string, type: TypeOptions, values?: any) => {
    if (msgs.indexOf(msg) >= 0) return;
    const m = msgs.slice();
    m.push(msg);
    setMsgs(m);
    toastify(<FormattedMessage values={values} id={msg} />, { type });
    if (process.env.NODE_ENV === 'test') return;
    setTimeout(() => {
      const m = msgs.slice();
      const index = m.findIndex((m) => m === msg);
      m.splice(index, 1);
      setMsgs(m);
    }, 5000);
  };
  return { toast };
}

export function useFloid(report: Report | null | undefined): {
  floid: string | undefined;
  getFloid: Function;
} {
  const childOrgs = useAppSelector(selectChildOrganisation);

  const getFloid = (report: Report | null | undefined): string | undefined => {
    if (!report) return undefined;
    let floid: string | undefined = report?.floid;
    if (!floid) {
      const org: any = childOrgs.find(({ id }) => id === report?.orgId);
      floid = org
        ? org?.data?.find(({ key }) => key === 'FLOID')?.value || ''
        : '';
    }
    return floid;
  };

  return { floid: getFloid(report), getFloid };
}

type FloIdAndPOOption = { label: string; value: ChildOrganisation['id'] };

export function useFloidAndPOOptions(): FloIdAndPOOption[] {
  const childOrganisation = useAppSelector(selectChildOrganisation);
  const floIdAndPOOptions: FloIdAndPOOption[] = useMemo(() => {
    if (childOrganisation?.length) {
      return childOrganisation.map(({ id, name, data }) => {
        const floid = data?.find(({ key }) => key === 'FLOID');
        return {
          label: `${floid ? floid.value : 'NA'} - ${name}`,
          value: id
        };
      });
    }
    return [];
  }, [childOrganisation]);
  return floIdAndPOOptions;
}

/**
 * This hook gives the category of the current user
 * based on the wrapper he is looking at.
 *
 * THIS CATEGORY IS ONLY VALID UNDER THE CONTEXT
 * OF A PARTICULAR WRAPPER
 */
export function useUserCategory(forWrapperId: PlanWrapper['id']): {
  userCategory: USER_CATEGORY | null;
  wrapperSummary: PlanWrapperSummary | null;
} {
  const profile = useAppSelector(selectProfile);
  const dispatch = useDispatch();
  const [wrapperSummary, setWrapperSummary] =
    useState<PlanWrapperSummary | null>(null);

  useEffect(() => {
    if (forWrapperId) {
      const response = dispatch(
        fetchWrapperSummary(forWrapperId)
      ) as unknown as Promise<PlanWrapperSummary>;
      response.then((data) => setWrapperSummary(data));
    }
  }, [dispatch, forWrapperId]);

  let userCategory = useMemo(() => {
    let category: USER_CATEGORY | null = null;
    if (profile && wrapperSummary?.id) {
      const { permissions } = profile;
      category = detectUserCategory(wrapperSummary, permissions);
    }
    return category;
  }, [wrapperSummary, profile]);

  return { userCategory, wrapperSummary };
}

export interface IStartYear {
  year: number;
  isDisabled: boolean;
  summary: unknown;
}

export interface IStartYearDetails {
  loading: boolean;
  startYears: IStartYear[] | null;
  error: unknown;
}

export function useStartYears(floid: string | number | undefined): IStartYearDetails {
  const [details, setDetails] = useState<IStartYearDetails>({
    loading: false,
    startYears: null,
    error: null
  });
  const dispatch = useDispatch();
  const processSummariesAndYears = (wrapperSummaries: PlanWrapperSummary[]) => {
    const startYears: IStartYear[] = [];
    const currentYear = new Date().getFullYear();
    const possibleStartYears = [
      currentYear - 2,
      currentYear - 1,
      currentYear,
      currentYear + 1
    ];
    if (currentYear === 2023) {
      possibleStartYears.unshift(currentYear - 3);
    }
    possibleStartYears.forEach((possibleStartYear) => {
      const wrapperSummary = wrapperSummaries.find(
        ({ startYear: wrapperStartYear }) =>
          wrapperStartYear === possibleStartYear
      );

      startYears.push({
        year: possibleStartYear,
        isDisabled: wrapperSummary && wrapperSummary.status >= 1 ? true : false,
        summary: wrapperSummary
      });
    });
    return startYears;
  };

  // useEffect(() => {
  //   if (floid) {
  //     const response = dispatch(
  //       fetchWrapperSummary2(floid)
  //     ) as unknown as Promise<IResponse>;
  //     setDetails({
  //       loading: true,
  //       startYears: null,
  //       error: null
  //     });
  //     response
  //       .then((response) => {
  //         const wrapperSummaries = response.data as PlanWrapperSummary[];
  //         let startYears: IStartYear[] | null = null;
  //         startYears = processSummariesAndYears(wrapperSummaries || []);
  //         setDetails({
  //           loading: false,
  //           startYears,
  //           error: !wrapperSummaries?.length ? 'NO WRAPPERS' : null
  //         });
  //       })
  //       .catch((response) => {
  //         //FIXME: Set proper Error
  //         setDetails({
  //           loading: false,
  //           startYears: null,
  //           error: 'INVALID'
  //         });
  //       });
  //   }
  // }, [dispatch, floid]);

  useEffect(() => { // this hook is called multiple time within a short period, and before, the return value may not be back with order, so this version is to make sure always the latest call value will be returned
    let isMounted = true;
    const fetchData = async () => {
      if (floid) {
        setDetails({
          loading: true,
          startYears: null,
          error: null
        });
        try {
          const response:any = await dispatch(fetchWrapperSummary2(floid));
          if (isMounted) {
            const wrapperSummaries = response.data as PlanWrapperSummary[];
            if (wrapperSummaries.length){
              const startYears = processSummariesAndYears(wrapperSummaries || []);
              setDetails({
                loading: false,
                startYears,
                error: null
              });
            } else { // for certain floid, backend will return empty, in this case, all reference period should be populated and enabled
              setDetails({
                loading: false,
                startYears:[
                  { year: 2020, isDisabled: false },
                  { year: 2021, isDisabled: false },
                  { year: 2022, isDisabled: false },
                  { year: 2023, isDisabled: false },
                  { year: 2024, isDisabled: false }
                ] as IStartYear[],
                error: 'NO WRAPPERS'
              });
            }
          }
        } catch (error) {
          if (isMounted) {
            setDetails({
              loading: false,
              startYears: null,
              error: 'INVALID'
            });
          }
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [dispatch, floid]);
  return details;
}
