import styled from 'styled-components';
import { Form as BSForm } from 'react-bootstrap';

export const TextContainer = styled.div<{ $width?: string, $fixedHeight?: string }>`
  /* margin-top: 1rem; */
  position: relative;
  width: ${props => props.$width || '100%'} !important;
  ${props => props.$fixedHeight && `height: ${props.$fixedHeight};`};
`;

export const Label = styled.label<any>`
  font-weight: ${props => props.$isLight ? '600' : 'normal'};
  font-size: 0.8em;
  margin-top: 1em;
  color: ${props => props.$disabled ? '#9B9B9B' : '#000000'};
`;

export const SubLabel = styled.label<any>`
  font-weight: normal;
  font-size: 0.7em;
  display: block;
  color: #5C5C5C;
`;

export const RightLabel = styled.div`
  position: absolute;
  bottom: 0.3em;
  right: 4em;
  font-size: 0.85em;
  color: grey;
`;

export const Icon = styled.label<{ hasLabel?: boolean }>`
  position: absolute;
  bottom: -0.25em;
  left: 0.75em;
  color: gray;
  font-size: 0.8em;
`;

export const Input = styled<any>(BSForm.Control)`
  border-color: ${props => props.disabled ? '#E4E2E4' : props.$error || props.$invalid ? 'var(--error-color)' : props.$isLight ? '#E4E2E4' : 'inherit' } !important;
  font-size: 0.8em !important;
  width: ${props => props.$width || '100%'} !important;
 ${props => props.$height  && `height: ${props.$height}!important;`}
  padding-left: ${props => props.$hasIcon ? '2em' : ''} !important;
  padding-right: ${props => props.$hasRightLabel ? '4em' : '0.5em'} !important;
  text-align: ${props => props.$alignRight ? 'right' : 'left'};
  &:disabled {
    background-color: transparent;
  }
`;

export const Textarea = styled.textarea<any>`
  border-color: ${props => props.$error ? 'var(--error-color)' : props.$isLight ? '#E8E8E8' : 'inherit' } !important;
  width: ${props => props.$width || '100%'} !important;
  height: ${props => props.$height || 'none'} !important;
  font-size: 0.8em !important;
`;
