import { useSelector } from 'react-redux';
import { selectProfile } from '../../login/redux';
import { PERMISSIONS } from '../../../appConstants';
import { useIntl } from 'react-intl';
import { SurveyHeader } from '../styled';

const SurveyHeadbar = () => {
  const profile = useSelector(selectProfile);
  const messages = useIntl().formatMessage;
  // user should be PO-Proxy to show this page
  const isPOProxy = profile?.permissions?.includes(PERMISSIONS.PO_PROXY_READ);
  // get flo-id
  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const floIdFromSearch = searchParams.get('floID');
  const parts = pathname.split('/');
  const floId = isPOProxy ? floIdFromSearch : parts[parts.length - 1];

  if (!isPOProxy || !pathname || !pathname.startsWith('/surveyItem') || !Number(floId)) return null;

  return (
    <SurveyHeader>
      {messages({ id: 'Survey_Taking_For' })} {Number(floId)}
    </SurveyHeader>
  );
};

export default SurveyHeadbar;