import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
`;

export const Container = styled.div<any>`
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.BG};
  color:  ${({ theme }) => theme.Text};
  display: flex;
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 4em;
  margin-top: 10em;
`;

export const RightSection = styled.div`
  width:100%;
`;

export const DemoMessage = styled.div`
  font-family: Roboto;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #000;
  padding: 0.5em;
  font-size: 20px;
  font-weight: 700;
  color: #F6F7F7;
  text-align: center;
  span {
    font-weight: 400;
  }
`;
